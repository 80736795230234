@charset "UTF-8";
/*** LTR websites ***/
/**/
/*===================================================
Project: flex-it - IT Solutions & Business Services Responsive HTML5 Bootstrap5  landing-page Template 
Auther: aminThemes
Version: 1.0
Last change: 30 November 2022
Template Description: IT Solutions & Business Services Responsive HTML5 Bootstrap5  landing-page Template 
====================================================*/
/*==
- global-rules and helper classes
- page-header
- page-hero 
- services
- about 
- portfolio 
- portfolio-single  
- our-team 
- our-clients 
- faq 
- testimonials 
- contact-us 
- take-action 
- page-footer 
- blog
- 404 Page
- pricing
- stats
- Dark Theme Rules
===*/
/* start global, helper classes , Components Rules*/
:root {
  /*Colors CSS Varibales*/
  /*================  main-color values */
  --clr-main: #09aff4;
  --clr-main-rgb: 9, 175, 244;
  /*================  accent-color values */
  --clr-accent: #0d1857;
  --clr-accent-rgb: 13, 24, 87;
  /*================  secondary-color values */
  --clr-secondary: #4820a7;
  --clr-secondary-rgb: 72, 32, 167;
  /*================  white-color values */
  --clr-white: #fff;
  --clr-white-rgb: 255, 255, 255;
  /*================  black-color values */
  --clr-black: #121212;
  --clr-black-rgb: 18, 18, 18;
  /*================  grey-color values */
  --clr-grey: #f1f1f1;
  --clr-grey-rgb: 241, 241, 241;
  /*================  semi-dark-color values */
  --clr-semi-dark: #b8b8b8;
  --clr-semi-dark-rgb: 184, 184, 184;
  /*================  dark-grey-color values */
  --clr-dark-grey: #4d4d4d;
  --clr-dark-grey-rgb: 77, 77, 77;
  /*================  extra-dark-grey-color values */
  --clr-extra-dark-grey: #202020;
  --clr-extra-dark-grey-rgb: 32, 32, 32;
  /*================  dark-blue-color values */
  --clr-dark-blue: #060922;
  --clr-dark-blue-rgb: 6, 9, 34;
  /*================  semi-dark-blue-color values */
  --clr-semi-dark-blue: #000531;
  --clr-semi-dark-blue-rgb: 0, 5, 49;
  /*================  danger-color values */
  --clr-danger: #fc0000;
  --clr-danger-rgb: 252, 0, 0;
  /*================  success-color values */
  --clr-success: #217234;
  --clr-success-rgb: 33, 114, 52;
  /*================  warning-color values */
  --clr-warning: #9b6a01;
  --clr-warning-rgb: 155, 106, 1;
  --ltr-main-font-f: "Jost", "sans-serif";
  --rtl-main-font-f: "Tajawal", "sans-serif";
}

/*========== Start Global Rules ==========*/
.sec-heading.centered .bottom-line, .sec-heading .bottom-line, .line-on-side, .line {
  display: block;
  position: relative;
  width: 5rem;
  height: 4px;
  background: var(--clr-main);
  border-radius: 1rem;
}

.pre-title {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  display: block;
  width: max-content;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--clr-main);
  z-index: 5;
}
.pre-title::before, .pre-title::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 6px;
  aspect-ratio: 1/1;
  border-radius: 1rem;
  top: 50%;
  translate: 0 -50%;
  background-color: var(--clr-main);
}
.pre-title::before {
  left: 0;
  right: auto;
}
.pre-title::after {
  left: auto;
  right: 0;
}

.overlay-photo-image-bg, .overlay-shape-image-bg, .overlay-pattern-image-bg, .overlay-gradient-color, .overlay-color {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: block;
  transition: all 0.5s ease-in-out 0s;
}

html {
  scroll-behavior: unset !important;
}

body {
  font-family: var(--ltr-main-font-f);
  direction: ltr;
  text-align: left;
  position: relative;
  color: var(--clr-dark-blue);
  background: var(--clr-white);
  overflow-x: hidden;
  scroll-behavior: unset;
  transition: all 0.25s ease-in-out 0s;
}

section {
  position: relative;
  z-index: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

.plain-section {
  overflow: visible;
  padding: 0;
}

.mega-section {
  padding: 5rem 0;
}
@media (max-width: 767px) {
  .mega-section {
    padding: 2.5rem 0;
  }
}

.elf-section {
  padding: 4rem 0;
}
@media (max-width: 767px) {
  .elf-section {
    padding: 3rem 0;
  }
}

.particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.text-linear-gradient {
  background: -webkit-linear-gradient(45deg, red, blue, green);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featured-text {
  color: var(--clr-main);
  position: relative;
}
.featured-text svg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc(100% + 10px);
  height: calc(100% + 40px);
  transform: translate(-50%, -50%);
}
.featured-text svg path {
  stroke: var(--clr-main);
  stroke-width: 0.5rem;
  fill: transparent;
}
.featured-text svg.wavey-underline {
  top: 100%;
}
.featured-text svg.wavey-underline path {
  fill: var(--clr-main);
}

.init-text {
  position: relative;
  z-index: 0;
  font-weight: 500;
  font-size: 1.1rem;
  font-style: italic;
  opacity: 0.85;
  line-height: 1.7;
  margin-bottom: 3rem;
  padding: 0.5rem 0;
  padding-left: 2rem;
  border-left: 4px solid var(--clr-secondary);
  transition: all 0.3s ease-in-out 0s;
}
.init-text::first-letter {
  text-transform: capitalize;
}
.init-text::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: var(--clr-secondary);
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out 0s;
}
.init-text:hover {
  color: var(--clr-white);
  opacity: 1;
}
.init-text:hover::before {
  transform: scaleX(1);
}

.line-on-center {
  margin-left: auto;
  margin-right: auto;
}

.line-on-side {
  margin-left: 0;
  margin-right: auto;
}
.line-on-side:before {
  left: 1rem;
}
.line-on-side:after {
  left: 2rem;
}

.sec-heading {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}
@media (max-width: 991px) {
  .sec-heading {
    flex-direction: column;
    align-items: flex-start;
  }
}
.sec-heading .cta-area {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}
@media (max-width: 991px) {
  .sec-heading .cta-area {
    width: 100%;
    justify-content: flex-start;
  }
}


.sec-heading .title {
  position: relative;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  line-height: 1.3;
  display: inline-block;
  z-index: 0;
}
@media (max-width: 767px) {
  .sec-heading .title {
    font-size: 2.5rem;
  }
}
.sec-heading .subtitle {
  margin: 0 auto;
  margin-bottom: 1.25rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: capitalize;
  opacity: 0.75;
}
@media (max-width: 767px) {
  .sec-heading .subtitle {
    width: 100%;
    font-size: 1rem;
  }
}
.sec-heading.light-title .title {
  font-size: 3rem;
  font-weight: 500;
}
@media (max-width: 991px) {
  .sec-heading.light-title .title {
    font-size: 2rem;
    font-weight: 400;
  }
}
.sec-heading.centered {
  display: block;
  text-align: center;
}
.sec-heading.centered .content-area {
  max-width: 100%;
}
.sec-heading.centered .pre-title {
  margin-left: auto;
  margin-right: auto;
}
.sec-heading.centered .cta-area {
  display: none;
}
.sec-heading.centered .bottom-line {
  display: block;
  margin: 0 auto;
}

.has-dark-bg {
  background-color: var(--clr-dark-blue);
}
.has-dark-bg .sec-heading .title,
.has-dark-bg .sec-heading .subtitle {
  color: var(--clr-white);
}
.has-dark-bg .sec-heading .bottom-line {
  background-color: var(--clr-white);
}

.parallax {
  background-attachment: fixed !important;
}

.overlay-color {
  background: var(--clr-dark-blue);
  opacity: 0.65;
}

.overlay-gradient-color {
  background: linear-gradient(45deg, red, blue, green);
}

.overlay-pattern-image-bg {
  opacity: 0.05;
  /*add  background-image value wherever you use this class to choose the custom image  */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.overlay-shape-image-bg {
  /*add  background-image value wherever you use this class to choose the custom image  */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.05;
}

.overlay-photo-image-bg {
  /*add  background-image value wherever you use this class to choose the custom image  */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.05;
}

.section-video-bg .video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -2;
}

.see-more-area {
  display: block;
  text-align: center;
  text-transform: capitalize;
}
.see-more-area.btn-on-start-align {
  text-align: left;
}

.no-borders {
  border: none;
  outline: none;
}

.no-box-shadow {
  box-shadow: none !important;
}

.section-shape-divider-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.section-shape-divider-bottom svg {
  display: block;
  width: 100%;
  height: 100%;
}
.section-shape-divider-bottom svg path {
  fill: var(--clr-secondary);
}

@media (max-width: 767px) {
  .rating-stars {
    padding: 0 1rem;
  }
}
.rating-stars .star-icon {
  color: var(--clr-main);
  font-size: 0.85rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out 0s;
}
.rating-stars .star-icon:not(:last-child) {
  margin-right: 0.5rem;
}
.rating-stars .star-icon.off {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: var(--clr-main);
  -webkit-text-stroke-width: 1px;
}
.rating-stars .star-icon.off:hover {
  -webkit-text-fill-color: var(--clr-main);
}

.skills .skill {
  margin-bottom: 1.5rem;
}
.skills .skill-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  font-weight: 600;
}
.skills .skill-data .skill-name {
  text-transform: capitalize;
}
.skills .skill-bar {
  width: 100%;
  height: 0.25rem;
  opacity: 0.75;
  background-color: var(--clr-semi-dark);
  border-radius: 1rem;
  overflow: hidden;
}
.skills .skill-bar .bar {
  width: 0;
  background-color: var(--clr-main);
  height: inherit;
  border-radius: inherit;
  transition: width 1s ease-in-out 0s;
}

.ma-pagination {
  margin: 2rem 0;
}
.ma-pagination .pagination .deactive-page-item {
  pointer-events: none;
  background-color: var(--clr-grey) !important;
  border-color: var(--clr-dark-blue) !important;
  opacity: 0.5;
}
.ma-pagination .pagination .deactive-page-item .ma-page-link {
  color: var(--clr-dark-blue) !important;
  opacity: 0.5;
}
.ma-pagination .pagination .ma-page-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  color: var(--clr-main);
  border: 1px solid;
  border-color: var(--clr-main);
  border-radius: 0.25rem;
  transition: all 0.3s ease 0s;
}
.ma-pagination .pagination .ma-page-item:not(:last-child) {
  margin-right: 0.5rem;
}
.ma-pagination .pagination .ma-page-item:hover, .ma-pagination .pagination .ma-page-item.active {
  background-color: var(--clr-main);
  color: var(--clr-white);
}
.ma-pagination .pagination .ma-page-item:hover .ma-page-link, .ma-pagination .pagination .ma-page-item.active .ma-page-link {
  color: var(--clr-white);
}
.ma-pagination .pagination .ma-page-item.active {
  pointer-events: none;
}
.ma-pagination .pagination .ma-page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: none !important;
  color: var(--clr-main);
  text-decoration: none;
}

.info-items-list .menu-items {
  margin: 0;
  padding: 2rem 0;
  list-style: none;
}
.info-items-list .info-item {
  margin-bottom: 3rem;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: row;

  
}

@media (max-width: 767px) {
  .info-items-list .info-item {
    margin-bottom: 2rem;
    flex-direction: column;
  }
}
.info-items-list .info-item:hover .info-icon,
.info-items-list .info-item:hover .info-img-icon {
  transform: translateY(-5px);
  background-color: rgba(var(--clr-main-rgb), 1);
  color: var(--clr-white);
}
.info-items-list .info-title {
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: capitalize;
}
.info-items-list .info-text {
  font-size: 1.1;
  opacity: 0.75;
}
.info-items-list .info-number {
  display: flex;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 0.8;
  color: var(--clr-main);
}
.info-items-list .info-icon {
  display: flex;
  margin-right: 1.25rem;
  margin-bottom: 1rem;
  color: var(--clr-main);
  font-size: 2rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: rgba(var(--clr-main-rgb), 0.15);
  transition: all 0.3s ease-in-out 0s;
}
.info-items-list .info-img-icon {
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease-in-out 0s;
}

*::selection {
  background: var(--clr-main);
  color: var(--clr-white);
}

/********* Start Design Elements ****************/
.design-element {
  position: absolute;
  width: 150px;
  height: 150px;
  opacity: 0.25;
  z-index: -1;
  left: 0;
  top: 0;
  transform: translate(-25%, -25%);
}
.design-element.rounded-shape {
  border-radius: 50%;
}
.design-element.stripes {
  background-image: repeating-linear-gradient(-55deg, transparent 0.9px, var(--clr-main) 1.25px, var(--clr-main) 3px, transparent 4px, transparent 8px);
}
.design-element.dots {
  background-image: radial-gradient(var(--clr-main) 2px, transparent 2.5px);
  background-size: 0.75rem 0.75rem;
}

/********* End Design Elements ****************/
/*========== End Global Rules ==========*/
/* Start video button animation*/
@keyframes anim-pulses {
  0% {
    transform: scale3d(0.9, 0.9, 1);
    opacity: 0.5;
  }
  50% {
    transform: scale3d(1.2, 1.2, 1);
    opacity: 0.75;
  }
  100% {
    transform: scale3d(1.4, 1.4, 1);
    opacity: 0;
  }
}
/* End video button animation*/
/* Start buttons global rules*/
.btn-outline, .btn-solid {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  outline: none;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0.75rem 2.25rem;
  font-size: 1.1rem;
  font-weight: 500;
  box-shadow: 0px 3px 10px -5px rgba(var(--clr-dark-blue-rgb), 0.15);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  z-index: 0;
}
.btn-outline .icon, .btn-solid .icon {
  display: flex;
  margin-left: 0.5rem;
  font-size: 1.5rem;
  line-height: 1;
  transition: transform 0.3s ease-in-out 0s;
}
.btn-outline:hover .icon, .btn-solid:hover .icon {
  transform: translatex(5px);
}
.btn-outline:focus, .btn-solid:focus {
  outline: none !important;
}
.btn-medium.btn-outline, .btn-medium.btn-solid {
  padding: 0.625rem 2rem;
}
.btn-small.btn-outline, .btn-small.btn-solid {
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.9rem;
}
@media (max-width: 575px) {
  .btn-outline, .btn-solid {
    padding: 0.5rem 1.25rem;
  }
}

.play-btn-row-dir, .play-btn-col-dir {
  display: inline-block;
}
.play-btn-row-dir .play-video-btn, .play-btn-col-dir .play-video-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.play-btn-row-dir .play-video-btn:hover .play-btn, .play-btn-col-dir .play-video-btn:hover .play-btn {
  background-color: var(--clr-white);
}
.play-btn-row-dir .play-video-btn:hover .play-btn .icon, .play-btn-col-dir .play-video-btn:hover .play-btn .icon {
  color: var(--clr-main);
}
.play-btn-row-dir .play-video-btn:hover .play-btn::before, .play-btn-col-dir .play-video-btn:hover .play-btn::before, .play-btn-row-dir .play-video-btn:hover .play-btn::after, .play-btn-col-dir .play-video-btn:hover .play-btn::after {
  background-color: inherit;
}
.play-btn-row-dir .play-btn, .play-btn-col-dir .play-btn {
  position: relative;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  background-color: var(--clr-main);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  transform-origin: center;
  box-shadow: 1px 1px 10px 5px rgba(var(--clr-dark-blue-rgb), 0.25);
  transition: all 0.3s ease-in-out 0s;
}
.play-btn-row-dir .play-btn::before, .play-btn-col-dir .play-btn::before, .play-btn-row-dir .play-btn::after, .play-btn-col-dir .play-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: inherit;
  animation: anim-pulses infinite 2s linear;
  z-index: -1;
}
.play-btn-row-dir .play-btn::after, .play-btn-col-dir .play-btn::after {
  animation-delay: 0.75s;
}
.play-btn-row-dir .play-btn .icon, .play-btn-col-dir .play-btn .icon {
  color: var(--clr-white);
}
.play-btn-row-dir .play-btn img.play-icon, .play-btn-col-dir .play-btn img.play-icon {
  width: 3rem;
  height: 3rem;
}
.inverted-colors.play-btn-row-dir .play-btn, .inverted-colors.play-btn-col-dir .play-btn {
  background-color: var(--clr-main);
}
.inverted-colors.play-btn-row-dir:hover .play-btn, .inverted-colors.play-btn-col-dir:hover .play-btn {
  background-color: var(--clr-secondary);
}
.play-btn-row-dir .play-btn-text, .play-btn-col-dir .play-btn-text {
  position: relative;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--clr-white);
}
.play-btn-row-dir .play-btn-text::before, .play-btn-col-dir .play-btn-text::before {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  height: 0.25rem;
  width: 100%;
  border-radius: 0.25rem;
  transform: scaleX(0);
  transform-origin: center;
  transition: all 0.3s ease-in-out 0s;
  background-color: var(--clr-white);
}

.btn-solid {
  color: var(--clr-white);
  background-color: var(--clr-main);
  border-color: var(--clr-main);
}
.btn-solid:hover {
  color: var(--clr-main);
  background-color: transparent;
}

.btn-outline {
  /**/
  border: 2px solid;
  color: var(--clr-main);
  border-color: var(--clr-main);
  background-color: transparent;
}
.btn-outline:hover {
  color: var(--clr-white);
  background-color: var(--clr-main);
}

.play-btn-col-dir .play-video-btn {
  flex-direction: column;
}
.play-btn-col-dir .play-btn-text {
  margin-top: 0.75rem;
}

.play-btn-row-dir .play-video-btn {
  flex-direction: row;
}
.play-btn-row-dir .play-btn-text {
  margin-left: 0.75rem;
}

/* Start Social icons list rules */
.sc-wrapper .sc-list {
  padding: 0;
  margin: 0;
  display: inline-flex;
  list-style: none;
}
.sc-wrapper .sc-list .sc-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-white);
  background-color: var(--clr-main);
  border-radius: 0.25rem;
  transition: transform 0.3s ease-in-out 0s;
}
.sc-wrapper .sc-list .sc-item:last-of-type {
  margin-bottom: 0;
}
.sc-wrapper .sc-list .sc-item:hover {
  /* background-color: var(--clr-white); */
  box-shadow: 0px 3px 15px 0px rgba(var(--clr-dark-blue-rgb), 0.25);
}
.sc-wrapper .sc-list .sc-item:hover .sc-icon {
  color: var(--clr-main);
}
.sc-wrapper .sc-list .sc-link {
  display: flex;
  line-height: 1;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
}
.sc-wrapper .sc-list .sc-icon {
  transition: color 0.3s ease-in-out 0s;
}

.sc-wrapper.dir-row {
  padding: 0.75rem 0;
}
.sc-wrapper.dir-row .sc-list .sc-item {
  margin-right: 10px;
}
.sc-wrapper.dir-row .sc-list .sc-item:hover {
  transform: translateY(-5px);
}
.sc-wrapper.dir-col {
  padding: 0 0.75rem;
}
.sc-wrapper.dir-col .sc-list {
  flex-direction: column;
}
.sc-wrapper.dir-col .sc-list .sc-item {
  margin-bottom: 10px;
}
.sc-wrapper.dir-col .sc-list .sc-item:last-of-type {
  margin-bottom: 0;
}
.sc-wrapper.dir-col .sc-list .sc-item:hover {
  transform: translateX(5px);
}
.sc-wrapper.sc-flat .sc-list .sc-item {
  font-size: 1.1rem;
  border-radius: 0;
  color: inherit;
  background-color: transparent;
  margin-right: 1.5rem;
}
.sc-wrapper.sc-flat .sc-list .sc-item:last-child {
  margin-right: 0;
}
.sc-wrapper.sc-flat .sc-list .sc-item:hover {
  background-color: transparent;
  box-shadow: none;
}
.sc-wrapper.sc-flat .sc-list .sc-item:hover .sc-icon {
  color: var(--clr-main);
}
.sc-wrapper.sc-size-16 .sc-item {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.sc-wrapper.sc-size-24 .sc-item {
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
}
.sc-wrapper.sc-size-32 .sc-item {
  width: 32px;
  height: 32px;
  font-size: 0.85rem;
}
.sc-wrapper.sc-size-40 .sc-item {
  width: 40px;
  height: 40px;
  font-size: 1rem;
}
.sc-wrapper.sc-size-50 .sc-item {
  width: 50px;
  height: 50px;
  font-size: 1.25rem;
}
@media (max-width: 575px) {
  .sc-wrapper.sc-size-50 .sc-item {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
}

/* End Social icons list rules */
.back-to-top {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: var(--clr-white);
  background-color: rgba(var(--clr-main-rgb), 1);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
  position: fixed;
  bottom: -100px;
  right: 30px;
  z-index: 20;
  box-shadow: 0px 3px 15px 0px rgba(var(--clr-dark-blue-rgb), 0.25);
}
.back-to-top:hover {
  background-color: rgba(var(--clr-main-rgb), 0.8);
}
.back-to-top:after {
  content: "";
}
.back-to-top .icon {
  display: flex;
}
.back-to-top.show {
  bottom: 30px;
}

.mode-switcher {
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}
.mode-switcher.dark-theme {
  background-color: var(--clr-white);
}
.mode-switcher.dark-theme .icon {
  color: var(--clr-dark-blue);
}
.mode-switcher.dark-theme .go-light {
  visibility: visible;
  opacity: 1;
}
.mode-switcher.dark-theme .go-dark {
  visibility: hidden;
  opacity: 1;
}
.mode-switcher.light-theme {
  background-color: var(--clr-dark-blue);
}
.mode-switcher.light-theme .icon {
  color: var(--clr-white);
}
.mode-switcher.light-theme .go-light {
  visibility: hidden;
  opacity: 0;
}
.mode-switcher.light-theme .go-dark {
  visibility: visible;
  opacity: 1;
}
.mode-switcher .switch-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
}
.mode-switcher .icon {
  display: flex;
  font-size: 1.25rem;
  padding: 0 0.5rem;
}

body.rounded-btns .btn-solid,
body.rounded-btns .btn-outline,
body.rounded-btns .portfolio-btn,
body.rounded-btns .subscripe-btn,
body.rounded-btns .sc-item,
body.rounded-btns .back-to-top,
body.rounded-btns .field-group .text-input,
body.rounded-btns .swiper-button-prev,
body.rounded-btns .swiper-button-next,
body.rounded-btns .read-more {
  border-radius: 5rem;
}

.btn-squared {
  border-radius: 0 !important;
}

.btn-rounded {
  border-radius: 5rem !important;
}

.read-more {
  text-transform: capitalize;
  color: var(--clr-main);
  font-weight: 600;
  position: relative;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out 0s;
}
.read-more:hover {
  color: var(--clr-main);
  text-decoration: none;
  border-color: rgba(var(--clr-main-rgb), 1);
}
.read-more:hover .icon {
  transform: translateX(5px);
}
.read-more .icon {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  transition: transform 0.3s ease-in-out 0s;
}

/* End buttons global rules*/
.breadcrumb {
  display: block;
  background: transparent;
  margin: 0;
  padding: 0;
}
.breadcrumb .icon {
  margin-right: 0.5rem;
}
.breadcrumb-item {
  display: inline-block;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
}
.breadcrumb-item.active {
  color: var(--clr-white);
  opacity: 0.75;
}
@media (max-width: 1199px) {
  .breadcrumb-item {
    font-size: 1rem;
  }
}
.breadcrumb-item:before {
  /*Breadcrumb Separator Color*/
  color: var(--clr-white) !important;
  padding-right: 0.5rem;
}
.breadcrumb-link {
  color: var(--clr-white);
  text-decoration: none;
  transition: color 0.25s ease-in 0s;
}
.breadcrumb-link:hover {
  color: var(--clr-main);
}

.req {
  color: var(--clr-danger);
}

.not-req {
  display: none;
}

/* conatct forms rules */
.custom-form-area {
  position: relative;
}
.custom-form-area.input-boxed .input-label, .custom-form-area.input-under-lined .input-label {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  position: absolute;
  left: 0;
  top: 0px;
  transition: all 0.3s ease-in-out 0s;
}
.custom-form-area.input-boxed input[type=email]:focus ~ .input-label,
.custom-form-area.input-boxed input[type=text]:focus ~ .input-label,
.custom-form-area.input-boxed input[type=password]:focus ~ .input-label,
.custom-form-area.input-boxed input[type=url]:focus ~ .input-label,
.custom-form-area.input-boxed input[type=search]:focus ~ .input-label,
.custom-form-area.input-boxed input[type=number]:focus ~ .input-label,
.custom-form-area.input-boxed textarea:focus ~ .input-label, .custom-form-area.input-boxed .input-wrapper.has-text .input-label, .custom-form-area.input-under-lined input[type=email]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=text]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=password]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=url]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=search]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=number]:focus ~ .input-label,
.custom-form-area.input-under-lined textarea:focus ~ .input-label, .custom-form-area.input-under-lined .input-wrapper.has-text .input-label, .custom-form-area input[type=email]:focus ~ .input-label,
.custom-form-area input[type=text]:focus ~ .input-label,
.custom-form-area input[type=password]:focus ~ .input-label,
.custom-form-area input[type=url]:focus ~ .input-label,
.custom-form-area input[type=search]:focus ~ .input-label,
.custom-form-area input[type=number]:focus ~ .input-label,
.custom-form-area select:focus ~ .input-label,
.custom-form-area textarea:focus ~ .input-label {
  top: -30px;
  color: var(--clr-main);
}
.custom-form-area .error-msg {
  position: absolute;
  right: 1rem;
  top: 0;
  transform: translateY(50%);
  font-size: 0.85rem;
  color: var(--clr-danger);
  display: none;
}
.custom-form-area .done-msg {
  position: absolute;
  right: 1rem;
  bottom: 0;
  font-size: 1rem;
  background-color: var(--clr-success);
  color: var(--clr-white);
  padding: 0.5rem 0;
  visibility: hidden;
  opacity: 0;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  z-index: 11111111;
  transition: all 0.5s ease-in-out 0s;
}
.custom-form-area .done-msg.show {
  visibility: visible;
  opacity: 1;
  margin-bottom: 5rem;
}
.custom-form-area .input-wrapper {
  position: relative;
  margin-bottom: 3rem;
}
.custom-form-area .input-wrapper .input-icon {
  position: absolute;
  left: auto;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem;
  pointer-events: none;
}
.custom-form-area input[type=email],
.custom-form-area input[type=text],
.custom-form-area input[type=password],
.custom-form-area input[type=url],
.custom-form-area input[type=search],
.custom-form-area input[type=number],
.custom-form-area select,
.custom-form-area textarea {
  display: block;
  width: 100%;
  padding: 0.75rem;
  outline: none;
  border: 1px solid rgba(var(--clr-dark-blue-rgb), 0.25);
  background: var(--clr-white);
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out 0s;
}
.custom-form-area input[type=email] [type=submit]:focus,
.custom-form-area input[type=text] [type=submit]:focus,
.custom-form-area input[type=password] [type=submit]:focus,
.custom-form-area input[type=url] [type=submit]:focus,
.custom-form-area input[type=search] [type=submit]:focus,
.custom-form-area input[type=number] [type=submit]:focus,
.custom-form-area select [type=submit]:focus,
.custom-form-area textarea [type=submit]:focus {
  outline: none !important;
}
.custom-form-area select {
  appearance: none;
  text-transform: capitalize;
}
.custom-form-area textarea {
  min-height: 10rem;
}
.custom-form-area input[type=submit] {
  outline: none;
  border: none;
}
.custom-form-area.input-under-lined .input-wrapper.has-text .input-label {
  top: -25px;
}
.custom-form-area.input-under-lined .input-wrapper.has-text .b-border {
  transform: scaleX(1);
}
.custom-form-area.input-under-lined .input-wrapper .b-border {
  display: block;
  width: 100%;
  transform: scaleX(0);
  transform-origin: center;
  height: 2px;
  background: var(--clr-main);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease-in-out 0s;
}
.custom-form-area.input-under-lined input[type=email],
.custom-form-area.input-under-lined input[type=text],
.custom-form-area.input-under-lined input[type=password],
.custom-form-area.input-under-lined input[type=url],
.custom-form-area.input-under-lined input[type=search],
.custom-form-area.input-under-lined input[type=number],
.custom-form-area.input-under-lined textarea {
  padding: 0.5rem 0;
  border: none;
  border-bottom: 1px solid rgba(var(--clr-main-rgb), 0.35);
}
.custom-form-area.input-under-lined input[type=email]:focus ~ .b-border,
.custom-form-area.input-under-lined input[type=text]:focus ~ .b-border,
.custom-form-area.input-under-lined input[type=password]:focus ~ .b-border,
.custom-form-area.input-under-lined input[type=url]:focus ~ .b-border,
.custom-form-area.input-under-lined input[type=search]:focus ~ .b-border,
.custom-form-area.input-under-lined input[type=number]:focus ~ .b-border,
.custom-form-area.input-under-lined textarea:focus ~ .b-border {
  transform: scaleX(1);
}
.custom-form-area.input-under-lined input[type=email]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=text]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=password]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=url]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=search]:focus ~ .input-label,
.custom-form-area.input-under-lined input[type=number]:focus ~ .input-label,
.custom-form-area.input-under-lined textarea:focus ~ .input-label {
  top: -25px;
}
.custom-form-area.input-boxed {
  padding-top: 30px;
}
.custom-form-area.input-boxed .input-label {
  top: -30px;
}
.custom-form-area.input-boxed input[type=email]:focus,
.custom-form-area.input-boxed input[type=text]:focus,
.custom-form-area.input-boxed input[type=password]:focus,
.custom-form-area.input-boxed input[type=url]:focus,
.custom-form-area.input-boxed input[type=search]:focus,
.custom-form-area.input-boxed input[type=number]:focus,
.custom-form-area.input-boxed textarea:focus {
  border-color: rgba(var(--clr-main-rgb), 1);
}
/* mailchimp forms rules*/
.mailchimp-form label,
.mailchimp-form .input-label {
  margin-bottom: 0;
  color: var(--clr-grey);
}
.mailchimp-form input:not([type=submit]) {
  color: var(--clr-white) !important;
}
.mailchimp-form .mc-form-area {
  position: relative;
}

.field-group {
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 1rem;
}
.field-group input[type=email],
.field-group input[type=text] {
  font-size: 1.1rem;
  width: 100%;
  padding: 0.75rem 1.5rem;
  outline: none !important;
  border: none;
  color: var(--clr-white);
  background: rgba(var(--clr-white-rgb), 0.35);
  backdrop-filter: blur(4px);
  margin-bottom: 0;
}
.field-group input[type=email]::-webkit-input-placeholder,
.field-group input[type=text]::-webkit-input-placeholder {
  color: var(--clr-white);
}
.field-group input[type=email] {
  text-align: left;
}

.one-field-form {
  margin-bottom: 2rem;
}
.one-field-form .field-group {
  margin-bottom: 0;
}
.one-field-form .email-label {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
  text-transform: capitalize;
  color: var(--clr-white);
}
.one-field-form input[type=email] {
  padding: 0.75rem;
  border-radius: 0.25rem;
}
.one-field-form .cta-area {
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
}
.one-field-form .cta-area .subscribe-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  min-height: 100%;
  padding: 0.75rem;
  border: none;
}
.one-field-form .cta-area .subscribe-btn:hover {
  background-color: var(--clr-main);
  border-color: var(--clr-main);
  color: var(--clr-white);
}
.one-field-form .email-notice {
  color: var(--clr-white);
  opacity: 0.5;
  font-weight: 100;
  font-size: 0.85rem;
  text-transform: capitalize;
}

.three-field-form {
  padding: 2rem 1rem;
  background: var(--clr-white);
}
.three-field-form .form-heading {
  margin-bottom: 2rem;
  color: var(--clr-semi-dark-blue);
  border-bottom: 2px solid var(--clr-main);
  padding-bottom: 0.75rem;
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 600;
}
.three-field-form .highlight {
  color: var(--clr-main);
  font-weight: 800;
  text-transform: uppercase;
}
.three-field-form label,
.three-field-form .input-label {
  display: inline-block;
  margin-bottom: 0.25rem;
  color: var(--clr-semi-dark-blue);
  font-weight: 600;
}
.three-field-form input:not([type=submit]) {
  border: 1px solid rgba(var(--clr-main-rgb), 0.5);
  color: var(--clr-semi-dark-blue) !important;
  padding: 0.5rem 1rem !important;
}
.three-field-form input:not([type=submit]):hover, .three-field-form input:not([type=submit]):focus {
  border: 1px solid rgba(var(--clr-main-rgb), 1);
}
.three-field-form .cta-area {
  text-align: center;
  padding-top: 0.5rem;
}
.three-field-form.has-dark-bg {
  background-color: var(--clr-dark-blue);
}
.three-field-form.has-dark-bg .form-heading {
  color: var(--clr-white);
}
.three-field-form.has-dark-bg label,
.three-field-form.has-dark-bg .input-label {
  color: var(--clr-white);
}
.three-field-form.has-dark-bg input:not([type=submit]) {
  color: var(--clr-white) !important;
  background-color: var(--clr-semi-dark-blue) !important;
}

/*mailchimp alerts*/
.mailchimp-alerts {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: all 0.5s ease-in-out 0s;
}
.mailchimp-alerts.show-message {
  bottom: 0;
  top: auto;
  transform: translateY(120%);
  z-index: -1;
}
.mailchimp-alerts .mc-msg {
  color: var(--clr-white);
}
.mailchimp-alerts .mc-msg.active {
  padding: 0.5rem 0.75rem;
}
.mailchimp-alerts .mc-submitting {
  background-color: rgba(var(--clr-warning-rgb), 0.5);
}
.mailchimp-alerts .mc-success {
  background-color: rgba(var(--clr-success-rgb), 0.5);
}
.mailchimp-alerts .mc-error {
  background-color: rgba(var(--clr-danger-rgb), 0.5);
}

.loading-screen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: var(--clr-dark-blue);
}
.loading-screen .bar {
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: var(--clr-main);
}
.loading-screen .top-bar {
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
}
.loading-screen .down-bar {
  bottom: 0;
  top: auto;
  right: 0;
  left: auto;
}
.loading-screen .progress-line {
  position: absolute;
  width: 100%;
  top: 60%;
  left: 0;
  right: auto;
  height: 1px;
  background-color: rgba(var(--clr-white-rgb), 0.35);
  transform: scale(0);
  transform-origin: center;
}
.loading-screen .loading-counter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--clr-white);
  font-size: 3rem;
  font-weight: 800;
}

/* End loading screen rules */
.header-search-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(var(--clr-dark-blue-rgb), 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
  z-index: 1200;
}
.header-search-box.show {
  height: 100%;
  visibility: visible;
  opacity: 1;
}
.header-search-box.show .search-form {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.header-search-box .close-search {
  position: absolute;
  right: 25%;
  top: 25%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header-search-box .close-search:before, .header-search-box .close-search::after {
  content: "";
  height: 2px;
  width: 40px;
  background-color: var(--clr-white);
  display: block;
  transform-origin: center;
}
.header-search-box .close-search:before {
  transform: rotate(45deg) translateX(2px);
}
.header-search-box .close-search:after {
  transform: rotate(-45deg) translateX(1px);
}
@media (max-width: 767px) {
  .header-search-box .close-search {
    right: 5%;
  }
}
.header-search-box .search-form {
  position: relative;
  visibility: hidden;
  opacity: 0;
  transform: translateY(5rem);
  transition: all 0.5s ease-in-out 0s;
  transition-delay: 0.25s;
  width: 50%;
}
@media (max-width: 1199px) {
  .header-search-box .search-form {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .header-search-box .search-form {
    width: 80%;
  }
}
.header-search-box .search-form .search-lbl {
  visibility: hidden;
  opacity: 0;
  width: 0;
}
.header-search-box .search-form input[type=search]::-webkit-search-decoration,
.header-search-box .search-form input[type=search]::-webkit-search-cancel-button,
.header-search-box .search-form input[type=search]::-webkit-search-results-button,
.header-search-box .search-form input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.header-search-box .search-form .search-input {
  border: none;
  border-bottom: 2px solid var(--clr-main);
  font-size: 2.5rem;
  outline: none;
  color: var(--clr-white);
  background-color: transparent;
  width: 100%;
}
@media (max-width: 767px) {
  .header-search-box .search-form .search-input {
    font-size: 1.5rem;
  }
}
.header-search-box .search-form .search-btn {
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: auto;
  outline: none;
  border: none;
  background: transparent;
  color: var(--clr-main);
  font-size: 1.5rem;
}

/*ُEnd global, helper classes , components Rules*/
/* Start .page-header Rules   */
.header-basic .has-sub-menu > .sub-menu .sub-menu-link::before, .header-basic .menu-link::before {
  content: "";
  position: absolute;
  left: -12px;
  width: 6px;
  aspect-ratio: 1/1;
  background: var(--clr-main);
  scale: 0;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.header-basic .has-sub-menu > .sub-menu .active.sub-menu-link, .header-basic .active.menu-link, .header-basic .has-sub-menu > .sub-menu .sub-menu-link:hover, .header-basic .menu-link:hover {
  color: var(--clr-main);
}
.header-basic .has-sub-menu > .sub-menu .active.sub-menu-link::before, .header-basic .active.menu-link::before, .header-basic .has-sub-menu > .sub-menu .sub-menu-link:hover::before, .header-basic .menu-link:hover::before {
  scale: 1;
}

.content-always-light.header-basic .menu-link, .is-sticky.header-basic .menu-link {
  color: var(--clr-white);
}
.content-always-light.header-basic .header-logo .logo-img.light-logo, .is-sticky.header-basic .header-logo .logo-img.light-logo {
  display: block;
}
.content-always-light.header-basic .header-logo .logo-img.dark-logo, .is-sticky.header-basic .header-logo .logo-img.dark-logo {
  display: none;
}
.content-always-light.header-basic .controls-box .header-search-btn, .is-sticky.header-basic .controls-box .header-search-btn {
  color: var(--clr-white);
}

.header-basic {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  border-bottom: 1px solid transparent;
}
.is-sticky.header-basic {
  background-color: var(--clr-main);
}
.is-sticky.header-basic .menu-link {
  color: var(--clr-white) !important;
}
.is-sticky.header-basic .menu-link::before {
  background-color: var(--clr-white) !important;
}
.header-basic .controls-box {
  display: flex;
  align-items: center;
  /* navbar open btn icon rules  */
}
.header-basic .controls-box .control {
  margin-right: 1rem;
  width: 2rem;
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 100;
  cursor: pointer;
}
.header-basic .controls-box .control:last-child {
  margin-right: 0;
}
.header-basic .controls-box .header-search-btn {
  display: flex;
}
.header-basic .controls-box .header-search-btn .icon {
  color: inherit;
  font-size: 1.5rem;
}
.header-basic .controls-box .menu-toggler {
  margin-right: 1rem;
  display: none;
}
.header-basic .controls-box .menu-toggler > span {
  width: 30px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--clr-white);
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 1199px) {
  .header-basic .controls-box .menu-toggler {
    display: flex;
  }
}
.header-basic .controls-box .close-menu span:nth-child(1) {
  transform: rotate(225deg) translateY(-9px);
}
.header-basic .controls-box .close-menu span:nth-child(2) {
  display: none;
}
.header-basic .controls-box .close-menu span:nth-child(3) {
  transform: rotate(-45deg) translateY(-9px);
}
@media (max-width: 1199px) {
  .header-basic {
    background-color: var(--clr-main);
  }
  .header-basic .controls-box .header-search-btn {
    color: var(--clr-white);
  }
}
.header-basic .menu-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  line-height: 64px;
}
@media (max-width: 1199px) {
  .header-basic .menu-navbar {
    height: 90px;
  }
}
.header-basic .menu-wrapper {
  margin-right: auto;
  margin-left: auto;
}
.header-basic .menu-wrapper.links-at-end {
  margin-left: auto;
  margin-right: 1rem;
}
.header-basic .menu-wrapper.links-at-start {
  margin-right: auto;
  margin-left: 2rem;
}
@media (max-width: 1199px) {
  .header-basic .menu-wrapper {
    position: fixed;
    left: -100%;
    width: 100%;
    height: 100%;
    top: 90px;
    margin: 0;
    transition: all 0.5s ease-in-out 0s;
  }
}
.header-basic .menu-wrapper.show {
  left: 0;
}
@media (max-width: 1199px) {
  .header-basic .menu-wrapper.show .links-list {
    left: 0;
    height: calc(100% - 90px);
  }
}
.header-basic .header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-basic .header-logo .logo-img {
  display: block;
  max-width: 10rem;
  max-height: calc(90px - 10px);
  transition: all 0.5s ease-in-out 0s;
}
.header-basic .header-logo .logo-img.light-logo {
  display: none;
}
.header-basic .header-logo .logo-img.dark-logo {
  display: block;
}
.header-basic .links-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (max-width: 1199px) {
  .header-basic .links-list {
    position: absolute;
    top: 0;
    width: 50%;
    max-height: calc(100% - 90px);
    padding: 1rem 1.5rem;
    flex-direction: column;
    align-items: start;
    justify-content: unset;
    overflow: auto;
    overflow-x: hidden;
    background-color: var(--clr-main);
  }
}
@media (max-width: 767px) {
  .header-basic .links-list {
    width: 75%;
  }
}
.header-basic .menu-item {
  position: relative;
  padding: 0 1rem;
}
@media (max-width: 1199px) {
  .header-basic .menu-item {
    width: 100%;
    padding: 0;
  }
}
.header-basic .menu-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: none !important;
  color: inherit;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
  line-height: 0;
  padding: 0;
  transition: all 0.3s ease-in-out 0s;
}
@media (max-width: 1199px) {
  .header-basic .menu-link {
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 0;
    color: var(--clr-white);
    line-height: calc(90px / 2);
  }
}
.header-basic .menu-link .plus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  line-height: 1;
  font-size: 0.625rem;
  margin-left: 0.25rem;
}
@media (max-width: 1199px) {
  .header-basic .menu-link .plus-icon {
    margin-left: 0.5rem;
  }
}
.header-basic .has-sub-menu > .sub-menu {
  list-style: none;
  position: absolute;
  top: 100%;
  min-width: 18rem;
  left: 0;
  margin: 0;
  padding: 1rem 1.5rem;
  transform: translateY(4rem);
  visibility: hidden;
  opacity: 0;
  z-index: 15000;
  background-color: var(--clr-main);
  transition: all 0.3s ease-in-out 0s;
}
@media (max-width: 1199px) {
  .header-basic .has-sub-menu > .sub-menu {
    position: static;
    display: none;
    visibility: visible;
    opacity: 1;
    border: none;
    box-shadow: none;
    padding: 0;
    transform: translateY(0);
    transition: none;
  }
}
.header-basic .has-sub-menu > .sub-menu .sub-menu-item {
  padding: 0;
}
@media (max-width: 1199px) {
  .header-basic .has-sub-menu > .sub-menu .sub-menu-item {
    padding-left: 1rem;
  }
}
.header-basic .has-sub-menu > .sub-menu .sub-menu-link {
  line-height: unset;
  color: var(--clr-white);
  padding: 1rem;
  padding-left: 0;
}
.header-basic .has-sub-menu > .sub-menu .sub-menu-link.active, .header-basic .has-sub-menu > .sub-menu .sub-menu-link:hover {
  color: var(--clr-white);
  translate: 4px 0;
}
.header-basic .has-sub-menu > .sub-menu .sub-menu-link.active::before, .header-basic .has-sub-menu > .sub-menu .sub-menu-link:hover::before {
  background: var(--clr-white);
  scale: 1;
}
@media (max-width: 1199px) {
  .header-basic .has-sub-menu > .sub-menu .sub-menu-link {
    line-height: unset;
    padding: 1rem 1rem 1rem 0rem !important;
  }
}
.header-basic .has-sub-menu:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 1199px) {
  .header-basic .header-logo .logo-img.light-logo {
    display: block;
  }
  .header-basic .header-logo .logo-img.dark-logo {
    display: none;
  }
  .header-basic .menu-link.active, .header-basic .menu-link:hover {
    color: var(--clr-white);
  }
  .header-basic .menu-link.active::before, .header-basic .menu-link:hover::before {
    background-color: var(--clr-white);
  }
}
.header-basic.inner-page-header .header-logo .logo-img.light-logo {
  display: block;
}
.header-basic.inner-page-header .header-logo .logo-img.dark-logo {
  display: none;
}
.header-basic.inner-page-header a,
.header-basic.inner-page-header .menu-link {
  color: var(--clr-white);
}
.header-basic.inner-page-header a::before,
.header-basic.inner-page-header .menu-link::before {
  background: var(--clr-white);
}
.header-basic.inner-page-header .controls-box .header-search-btn {
  color: var(--clr-white);
}

/*
 End .page-header Rules    
*/
/*   Start .page-hero Rules   */
.hero-swiper-slider .slide-title, .page-hero .hero-text-area .hero-title {
  font-weight: 700;
  font-size: 3.5rem;
  text-transform: capitalize;
  margin-bottom: 1.75rem;
  line-height: 1.5;
}
.hero-swiper-slider .slide-title .first-word, .page-hero .hero-text-area .hero-title .first-word {
  position: relative;
}
@media (max-width: 1199px) {
  .hero-swiper-slider .slide-title, .page-hero .hero-text-area .hero-title {
    font-size: 3rem !important;
  }
}
@media (max-width: 575px) {
  .hero-swiper-slider .slide-title, .page-hero .hero-text-area .hero-title {
    font-size: 2rem !important;
    font-weight: 700 !important;
  }
}

.hero-swiper-slider .slide-subtitle, .page-hero .hero-text-area .hero-subtitle {
  opacity: 0.75;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
@media (max-width: 991px) {
  .hero-swiper-slider .slide-subtitle, .page-hero .hero-text-area .hero-subtitle {
    font-size: 1rem;
  }
}

.hero-social-icons .sc-list .sc-item .sc-link {
  color: var(--clr-dark-blue);
}
.hero-social-icons .dir-row .sc-list .sc-item:not(:last-child) {
  margin-right: 2rem;
}
.hero-social-icons .dir-col .sc-list .sc-item:not(:last-child) {
  margin-bottom: 2rem;
}

.page-hero {
  position: relative;
  z-index: 0;
  max-height: 1280px;
  min-height: 100vh;
  padding: 100px 0;
}
.page-hero.tringle-bg::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 100%, 100% 0%, 100% 100%, 0 100%);
  background: rgba(var(--clr-secondary-rgb), 0.5);
  transition: all 0.25s ease-in-out 0s;
}
@media (max-width: 991px) {
  .page-hero.tringle-bg::before {
    clip-path: polygon(0 100%, 100% 50%, 100% 100%);
  }
}
@media (max-width: 991px) {
  .page-hero {
    height: auto;
    min-height: 100vh;
  }
}
@media (max-height: 600px) {
  .page-hero {
    height: auto;
  }
}
.page-hero .hero-image-area {
  position: relative;
}
.page-hero .hero-image-area .hero-img-wraper img {
  transform: scaleX(1);
}
.page-hero .hero-text-area {
  margin-top: 2.5rem;
  position: relative;
}
.page-hero .hero-text-area .hero-title {
  display: inline-block;
  position: relative;
  z-index: 0;
}
.page-hero .hero-text-area .hero-title .heading-brand-name {
  position: relative;
  display: inline-block;
  line-height: 1;
}
.page-hero .hero-text-area .hero-title .featured-text {
  font-weight: 700;
}
.page-hero .hero-text-area.content-always-light .hero-title,
.page-hero .hero-text-area.content-always-light .hero-subtitle,
.page-hero .hero-text-area.content-always-light .slide-title,
.page-hero .hero-text-area.content-always-light .slide-subtitle,
.page-hero .hero-text-area.content-always-light .hero-social-icons .sc-link {
  color: var(--clr-white) !important;
}
.page-hero .cta-links-area {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.page-hero .cta-links-area .cta-link {
  margin-bottom: 0;
  margin-right: 1.5rem;
  font-size: 1.15rem;
  min-width: 180px;
}
@media (max-width: 991px) {
  .page-hero .cta-links-area .cta-link {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    min-width: auto;
  }
}
.page-hero .cta-links-area .cta-link:last-child {
  margin-right: 0;
}

.hero-swiper-slider {
  padding: 0;
  background: var(--clr-dark-blue);
}
.hero-swiper-slider [data-splitting=chars] .word .char {
  text-transform: lowercase;
}
.hero-swiper-slider [data-splitting=chars] .word .char:first-child {
  text-transform: uppercase;
}
.hero-swiper-slider .swiper-slide {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  padding: 120px 0;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
@media (max-height: 720px) {
  .hero-swiper-slider .swiper-slide {
    height: auto;
  }
}
.hero-swiper-slider .slide-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: slide-bg-img-scale 24s linear alternate-reverse 0s infinite both;
}
.hero-swiper-slider .slide-bg-img .overlay-color {
  z-index: 0;
}
@keyframes slide-bg-img-scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
.hero-swiper-slider .pre-title {
  transform: translateY(-1rem);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
  transition-delay: 0.3s;
}
.hero-swiper-slider .slide-title {
  display: inline-block;
  transform: translateY(3rem);
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s ease-in-out 0s;
  transition-delay: 0.5s;
}
.hero-swiper-slider .slide-subtitle {
  transform: translateY(3rem);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
  transition-delay: 0.8s;
}
.hero-swiper-slider .cta-links-area {
  transform: translateY(3rem);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
  transition-delay: 1s;
}
.hero-swiper-slider .slider-stacked-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3rem;
  z-index: 11;
}
.hero-swiper-slider .slider-stacked-arrows .swiper-button-prev,
.hero-swiper-slider .slider-stacked-arrows .swiper-button-next {
  position: static;
  margin: 0.25rem 0;
  transform: none;
}
@media (max-width: 991px) {
  .hero-swiper-slider .slider-stacked-arrows {
    bottom: 1rem;
    top: auto;
    width: 100%;
    transform: none;
    left: 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.hero-swiper-slider .swiper-button-prev,
.hero-swiper-slider .swiper-button-next {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: var(--clr-white);
  background-color: rgba(var(--clr-main-rgb), 1);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
  background: rgba(var(--clr-white-rgb), 0.35);
  color: var(--clr-white);
  outline: none;
  top: 50%;
  transform: translateY(-50%);
}
.hero-swiper-slider .swiper-button-prev:hover,
.hero-swiper-slider .swiper-button-next:hover {
  background-color: rgba(var(--clr-main-rgb), 0.8);
}
.hero-swiper-slider .swiper-button-prev:after,
.hero-swiper-slider .swiper-button-next:after {
  content: "";
}
.hero-swiper-slider .swiper-button-prev .icon,
.hero-swiper-slider .swiper-button-next .icon {
  display: flex;
}
.hero-swiper-slider .swiper-button-prev:hover,
.hero-swiper-slider .swiper-button-next:hover {
  background: rgba(var(--clr-main-rgb), 0.85);
}
@media (max-width: 991px) {
  .hero-swiper-slider .swiper-button-prev,
  .hero-swiper-slider .swiper-button-next {
    bottom: 1rem;
    top: auto;
    transform: none;
  }
}
@media (max-width: 575px) {
  .hero-swiper-slider .swiper-button-prev,
  .hero-swiper-slider .swiper-button-next {
    display: none;
  }
}
.hero-swiper-slider .swiper-button-prev {
  left: 3rem;
  right: auto;
}
@media (max-width: 991px) {
  .hero-swiper-slider .swiper-button-prev {
    left: 10px;
  }
}
.hero-swiper-slider .swiper-button-next {
  right: 3rem;
  left: auto;
}
@media (max-width: 991px) {
  .hero-swiper-slider .swiper-button-next {
    right: 10px;
  }
}
.hero-swiper-slider .slides-state {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
}
.hero-swiper-slider .slides-state .slide-num {
  width: 3rem;
  height: 3rem;
  text-align: center;
  color: var(--clr-white);
  font-size: 2rem;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 575px) {
  .hero-swiper-slider .slides-state .slide-num {
    display: none;
  }
}
.hero-swiper-slider .slides-state .slide-num.slides-count {
  opacity: 0.5;
}
.hero-swiper-slider .slides-state .swiper-pagination {
  position: static;
  margin: 0 1rem;
  display: flex;
  align-items: center;
}
.hero-swiper-slider .slides-state .swiper-pagination-bullet {
  width: 1.5rem;
  height: 0.5rem;
  display: inline-block;
  border-radius: 2rem;
  background-color: var(--clr-white);
  opacity: 0.65;
  transition: all 0.5s ease-in-out 0s;
}
.hero-swiper-slider .slides-state .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--clr-main);
  width: 2.5rem;
  opacity: 1;
}
.hero-swiper-slider .slides-state .swiper-pagination-bullet:not(:last-child) {
  margin-right: 0.5rem !important;
}
@media (min-width: 768px) {
  .hero-swiper-slider .slides-state.v-align {
    right: -3rem;
    left: auto;
    transform: rotate(90deg) translateY(-50%);
    top: 50%;
    bottom: auto;
  }
  .hero-swiper-slider .slides-state.v-align .slide-num {
    transform: rotate(-90deg);
  }
}
.hero-swiper-slider .swiper-slide-active .pre-title,
.hero-swiper-slider .swiper-slide-active .slide-title,
.hero-swiper-slider .swiper-slide-active .slide-subtitle,
.hero-swiper-slider .swiper-slide-active .cta-links-area {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.hero-swiper-slider .swiper-slide-active .slide-title[data-splitting=words] .word {
  opacity: 0;
  /*only on fade family animations*/
  animation: fadeInLeft 1s both ease;
  animation-delay: calc(0.1s + 0.1s * var(--word-index));
}
.hero-swiper-slider .swiper-slide-active .slide-title[data-splitting=chars] .char {
  animation: rollIn 0.35s both ease;
  animation-delay: calc(0.1s + 0.06s * var(--char-index));
}
.hero-swiper-slider .swiper-slide-active .slide-title[data-splitting=chars].anim-fade-in-down .char {
  opacity: 0;
  animation: fadeInDown 0.35s both ease;
  animation-delay: calc(0.1s + 0.06s * var(--char-index));
}
.hero-swiper-slider .swiper-slide-active .slide-title[data-splitting=chars].anim-fade-in-up .char {
  opacity: 0;
  animation: fadeInUp 0.35s both ease;
  animation-delay: calc(0.1s + 0.06s * var(--char-index));
}
.hero-swiper-slider .swiper-slide-active .slide-title[data-splitting=chars].anim-rotate-in .char {
  animation: rotateIn 0.35s both ease;
  animation-delay: calc(0.1s + 0.06s * var(--char-index));
}

.inner-page-hero {
  height: 60vh;
  min-height: 60vh;
  opacity: 1;
}
.inner-page-hero:before {
  display: none;
}
.inner-page-hero .hero-text-area {
  margin: 0;
}
.inner-page-hero .hero-text-area .hero-title {
  display: block;
  color: var(--clr-white);
}
.inner-page-hero .hero-text-area .hero-title {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.inner-page-hero .hero-text-area.centerd {
  text-align: center;
}

/*   End .page-hero Rules   */
/*    Start .services Section Rules    */
.services-boxed .service-box {
  position: relative;
  padding: 4rem 2rem;
  transition: all 0.3s ease-in-out 0s;
  z-index: 0;
}
.services-boxed .service-card {
  overflow: hidden;
}
.services-boxed .service-icon {
  margin-bottom: 1rem;
  transition: all 0.5s ease-in-out 0s;
}
.services-boxed .font-icon {
  display: block;
  line-height: 3rem;
  font-size: 3rem;
  color: var(--clr-main);
  transform-origin: center;
  transition: all 0.3s ease-in-out 0s;
}
.services-boxed .service-num {
  font-size: 5rem;
  font-weight: 800;
  position: absolute;
  top: 1rem;
  right: 2rem;
  color: var(--clr-white);
  opacity: 0;
  line-height: 1;
}
.services-boxed .img-icon {
  width: 60px;
  height: 60px;
}
.services-boxed .service-content {
  margin-bottom: 1.5rem;
}
.services-boxed .service-title {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.services-boxed .service-text {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0;
  opacity: 0.85;
}
@media (max-width: 991px) {
  .lifted-up-box.services-boxed .services-row {
    padding: 6rem 0;
  }
}
.lifted-up-box.services-boxed .service-box {
  margin-top: -8rem;
}
@media (max-width: 991px) {
  .lifted-up-box.services-boxed .service-box {
    margin-top: 0rem;
  }
}

.services-boxed .services-row {
  margin-bottom: 2rem;
}
.services-boxed .service-box {
  border-radius: 0.5rem;
  padding: 2rem;
  border: 1px solid rgba(var(--clr-main-rgb), 0.35);
  box-shadow: 0px 0px 10px 0px rgba(var(--clr-dark-blue-rgb), 0.1);
}
.services-boxed .service-box::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
  background-color: rgba(var(--clr-main-rgb), 1);
  z-index: -3;
  transition: transform 0.5s ease 0s;
}
.services-boxed .service-box.reveal-up::before {
  left: 0;
  top: auto;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: bottom;
}
.services-boxed .service-box.reveal-up:hover::before {
  top: 0;
  bottom: auto;
  transform: scaleY(1);
  transform-origin: top;
}
.services-boxed .service-box.reveal-down::before {
  left: 0;
  top: 0;
  bottom: auto;
  transform: scaleY(0);
  transform-origin: top;
}
.services-boxed .service-box.reveal-down:hover::before {
  top: auto;
  bottom: 0;
  transform: scaleY(1);
  transform-origin: bottom;
}
.services-boxed .service-box.reveal-end::before {
  top: 0;
  left: 0;
  right: auto;
  transform: scaleX(0);
  transform-origin: left;
}
.services-boxed .service-box.reveal-end:hover::before {
  left: auto;
  right: 0;
  transform: scaleX(1);
  transform-origin: right;
}
.services-boxed .service-box.reveal-start::before {
  top: 0;
  left: auto;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
}
.services-boxed .service-box.reveal-start:hover::before {
  left: 0;
  right: auto;
  transform: scaleX(1);
  transform-origin: left;
}
@media (max-width: 991px) {
  .services-boxed .service-box {
    padding: 2rem;
  }
}
.services-boxed .service-box .read-more {
  color: var(--clr-main);
  border: none;
  padding: 0;
}
.services-boxed .service-box:hover::before {
  left: 0;
  right: auto;
  transform: scaleX(1);
  transform-origin: left;
}
.services-boxed .service-box:hover .service-title,
.services-boxed .service-box:hover .service-text,
.services-boxed .service-box:hover .font-icon,
.services-boxed .service-box:hover .read-more {
  color: var(--clr-white);
}
.services-boxed.has-dark-bg {
  background-color: var(--clr-semi-dark-blue);
}
.services-boxed.has-dark-bg .service-title {
  color: var(--clr-white);
}
.services-boxed.has-dark-bg .service-text {
  color: var(--clr-white);
  opacity: 0.85;
}

.service-single {
  padding: 8rem 0;
}
.service-single .service-sidebar {
  padding-inline-end: 1rem;
  position: sticky;
  top: 7.5rem;
}
.service-single .service-sidebar .sidebar-pane {
  position: relative;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.25rem;
  background-color: rgba(var(--clr-main-rgb), 0.75);
  z-index: 0;
}
.service-single .service-sidebar .sidebar-pane .sidebar-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
}
.service-single .service-sidebar .sidebar-pane .list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.service-single .service-sidebar .sidebar-pane .list-item {
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  padding-right: 0;
  margin-bottom: 1rem;
  background: rgba(var(--clr-dark-blue-rgb), 1);
  color: var(--clr-white);
  opacity: 0.65;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out 0s;
}
.service-single .service-sidebar .sidebar-pane .list-item .font-icon {
  color: var(--clr-white);
  font-size: 1.5rem;
  margin-right: 0.75rem;
  line-height: 1;
}
.service-single .service-sidebar .sidebar-pane .list-item a {
  position: relative;
  text-transform: capitalize;
  width: 100%;
  color: inherit;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 1.1rem;
  font-weight: 400;
}
.service-single .service-sidebar .sidebar-pane .list-item a .icon {
  display: flex;
  align-items: center;
  translate: -1rem 0;
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}
.service-single .service-sidebar .sidebar-pane .list-item:hover, .service-single .service-sidebar .sidebar-pane .list-item.active {
  opacity: 1;
}
.service-single .service-sidebar .sidebar-pane .list-item:hover a .icon, .service-single .service-sidebar .sidebar-pane .list-item.active a .icon {
  translate: 1rem 0;
  opacity: 1;
}
.service-single .service-sidebar .sidebar-pane .sc-list .sc-item {
  color: var(--clr-white);
  background-color: var(--clr-dark-blue);
  opacity: 0.75;
}
.service-single .service-sidebar .sidebar-pane .sc-list .sc-item .sc-link {
  color: inherit;
}
.service-single .service-sidebar .sidebar-pane .sc-list .sc-item .sc-icon {
  color: inherit;
}
.service-single .service-sidebar .sidebar-pane .sc-list .sc-item:hover {
  opacity: 1;
}
.service-single .service-content-area {
  margin-bottom: 4rem;
}
.service-single .service-content-area .part {
  margin-bottom: 2.5rem;
}
.service-single .service-content-area .featured-img-area {
  margin-bottom: 2rem;
  border-radius: 0.25rem;
  overflow: hidden;
}
.service-single .service-content-area .service-title {
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.75rem;
}
.service-single .service-content-area .service-text {
  margin-bottom: 2rem;
  font-size: 1.25rem;
  opacity: 0.75;
}
.service-single .service-content-area .two-cols-img {
  margin-bottom: 2rem;
}
.service-single .service-content-area .two-cols-img .img-col {
  border-radius: 0.25rem;
  overflow: hidden;
}
.service-single .service-content-area .two-cols-img .img-col img {
  min-width: 100%;
}

/*    End .services Section Rules   */
/*   Start about Section   */
.about .content-block:not(:last-child) {
  margin-bottom: 6rem;
}
.about .text-area {
  position: relative;
  padding: 3rem 0;
}
.about .text-area .sec-heading {
  margin-bottom: 1.5rem;
}
.about .text-area .about-text {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 3rem;
  opacity: 0.75;
  letter-spacing: 0.5px;
}
.about .text-area .about-text::first-letter {
  text-transform: capitalize;
}
.about .text-area .cta-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about .text-area .cta-area .signature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about .text-area .cta-area .signature .signature-img {
  max-width: 200px;
  width: 200px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 575px) {
  .about .text-area .cta-area .signature .signature-img {
    max-width: 150px;
    max-height: 50px;
  }
}
.about .text-area .cta-area .signature .signature-name {
  display: block;
  color: var(--clr-main);
  margin-top: -0.5rem;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .about .text-area .cta-area .signature .signature-name {
    font-size: 0.85rem;
  }
}

/*   End about Section    */
/*    Start portfolio Section  */
.portfolio {
  overflow: hidden;
  position: relative;
}
.portfolio .portfolio-wrapper {
  overflow: hidden;
  margin-bottom: 3rem;
  margin-left: -1rem;
  margin-right: -1rem;
}
.portfolio .portfolio-btn-list {
  list-style: none;
  padding: 0.25rem 0.5rem;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .portfolio .portfolio-btn-list {
    justify-content: center;
  }
}
.portfolio .portfolio-btn-list .portfolio-btn {
  position: relative;
  margin: 5px;
  padding: 0.5rem 0rem;
  margin-right: 1.25rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}
.portfolio .portfolio-btn-list .portfolio-btn:first-child {
  padding-left: 0;
}
.portfolio .portfolio-btn-list .portfolio-btn::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 50%;
  translate: 0 -50%;
  width: 6px;
  aspect-ratio: 1/1;
  background: var(--clr-main);
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out;
}
.portfolio .portfolio-btn-list .portfolio-btn.active, .portfolio .portfolio-btn-list .portfolio-btn:hover {
  color: var(--clr-main);
}
.portfolio .portfolio-btn-list .portfolio-btn.active::before, .portfolio .portfolio-btn-list .portfolio-btn:hover::before {
  opacity: 1;
}
.portfolio .portfolio-group {
  direction: ltr;
  position: relative;
}
.portfolio .portfolio-img-link {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none;
  z-index: 1;
  transition: all 0.5s ease-in-out 0s;
}
.portfolio .portfolio-item {
  position: relative;
  padding: 0.5rem;
  overflow: hidden;
  z-index: 1;
}
.portfolio .item {
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
}
.portfolio .item .portfolio-img {
  display: block;
  min-width: 100%;
  object-fit: cover;
  height: 450px;
  transition: scale 0.5s ease-in-out 0s;
}
.portfolio .item .portfolio-bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}
.portfolio .item .item-info {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;
  padding: 2rem 0;
  pointer-events: none;
  z-index: 5;
}
.portfolio .item .item-info .item-title {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  text-transform: capitalize;
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0 1rem;
  padding-left: 5rem;
  background-color: rgba(var(--clr-dark-blue-rgb), 0.75);
  color: var(--clr-white);
  top: 0;
  left: 0;
  transform: translateY(100%);
  transition: all 0.5s ease-in-out 0s;
}
.portfolio .item .item-info .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  width: max-content;
  height: 100%;
  padding: 0 1rem;
  font-size: 1.5rem;
  background-color: rgba(var(--clr-main-rgb), 0.75);
  color: var(--clr-white);
  transform: translateX(-120%);
  transition: transform 0.5s ease-in-out 0s;
  transition-delay: 0.3s;
}
.portfolio .item:hover .portfolio-img {
  scale: 1.1;
}
.portfolio .item:hover .item-info .item-title {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.portfolio .item:hover .item-info .icon {
  transform: translateX(0);
}

.portfolio-slider .item .portfolio-bg-img {
  height: 500px;
}
@media (max-width: 767px) {
  .portfolio-slider .item .portfolio-bg-img {
    height: 400px;
  }
}
.portfolio-slider .swiper-button-prev,
.portfolio-slider .swiper-button-next {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: var(--clr-white);
  background-color: rgba(var(--clr-main-rgb), 1);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}
.portfolio-slider .swiper-button-prev:hover,
.portfolio-slider .swiper-button-next:hover {
  background-color: rgba(var(--clr-main-rgb), 0.8);
}
.portfolio-slider .swiper-button-prev:after,
.portfolio-slider .swiper-button-next:after {
  content: "";
}
.portfolio-slider .swiper-button-prev .icon,
.portfolio-slider .swiper-button-next .icon {
  display: flex;
}

/*    End portfolio Section  */
/*   Start our-team Section   */
.our-team .tm-member-card {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 1px 20px 0px rgba(var(--clr-dark-blue-rgb), 0.15);
  margin-bottom: 2rem;
  text-align: center;
  transition: all 0.5s ease-in-out 0s;
}
.our-team .tm-member-card:hover .tm-image > img {
  transform: scale(1.05);
  transform-origin: center;
}
.our-team .tm-member-card:hover .tm-image .overlay-color {
  opacity: 0.65;
}
.our-team .tm-member-card:hover .tm-image .tm-social .sc-item {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.our-team .tm-member-card .tm-link {
  text-decoration: none !important;
}
.our-team .tm-member-card .tm-image {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out 0s;
}
.our-team .tm-member-card .tm-image .overlay-color {
  z-index: 2;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
}
.our-team .tm-member-card .tm-image img {
  width: 100%;
  transition: all 0.5s ease-in-out 0s;
}
.our-team .tm-member-card .tm-image .tm-social {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  padding: 1rem;
  z-index: 7;
}
.our-team .tm-member-card .tm-image .tm-social .sc-item {
  transform: translateY(150px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out 0s;
}
.our-team .tm-member-card .tm-image .tm-social .sc-item:nth-child(1) {
  transition-delay: 0.1s;
}
.our-team .tm-member-card .tm-image .tm-social .sc-item:nth-child(2) {
  transition-delay: 0.2s;
}
.our-team .tm-member-card .tm-image .tm-social .sc-item:nth-child(3) {
  transition-delay: 0.3s;
}
.our-team .tm-member-card .tm-image .tm-social .sc-item:nth-child(4) {
  transition-delay: 0.4s;
}
.our-team .tm-member-card .tm-image .tm-social .sc-item:nth-child(5) {
  transition-delay: 0.5s;
}
.our-team .tm-member-card .tm-image .tm-social .sc-item:nth-child(6) {
  transition-delay: 0.6s;
}
.our-team .tm-member-card .tm-details {
  text-align: center;
  padding: 2rem 0;
}
.our-team .tm-member-card .tm-details .tm-name {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
}
.our-team .tm-member-card .tm-details .tm-role {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.75;
}

.team-member .profile {
  text-align: center;
  padding-bottom: 3rem;
  margin-bottom: 4rem;
  box-shadow: 1px 1px 10px 0px rgba(var(--clr-dark-blue-rgb), 0.15);
}
.team-member .profile .tm-img {
  margin-bottom: 1.5rem;
}
.team-member .profile .tm-img > img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
.team-member .profile .tm-details {
  margin-bottom: 2rem;
}
.team-member .profile .tm-details .name {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}
.team-member .profile .tm-details .role {
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  opacity: 0.75;
}
.team-member .profile .tm-social {
  margin-bottom: 2rem;
}
.team-member .tm-description .tm-title {
  margin-top: 5rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 500;
  text-transform: capitalize;
}
.team-member .tm-description .tm-title:first-of-type {
  margin-top: 0;
}
@media (max-width: 991px) {
  .team-member .tm-description .tm-title {
    font-size: 1.75rem;
    font-weight: 600;
  }
}
.team-member .tm-description .tm-text {
  line-height: 1.6;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
  opacity: 0.75;
}
.team-member .tm-description .tm-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 0;
  margin-bottom: 2rem;
}
.team-member .tm-description .tm-list .list-item {
  width: 50%;
  margin-bottom: 0.5rem;
  position: relative;
  text-transform: capitalize;
  font-size: 1.1rem;
  opacity: 0.75;
  display: flex;
  align-items: baseline;
}
.team-member .tm-description .tm-list .list-item .icon {
  color: var(--clr-main);
  margin-right: 0.5rem;
}
@media (max-width: 767px) {
  .team-member .tm-description .tm-list .list-item {
    width: 100%;
  }
}

/*   End our-team Section   */
/*   Start our-clients Section   */
.our-clients {
  background-color: var(--clr-main);
}
.our-clients .sec-heading .title {
  font-size: 1.75rem;
  opacity: 0.35;
  color: var(--clr-white);
}
.our-clients .clients-logos {
  text-align: center;
}
.our-clients .client-logo {
  background: var(--clr-white);
  border-radius: 0.5rem;
}
.our-clients .logo {
  max-height: 125px;
  border-radius: 0.25rem;
  filter: grayscale(1);
}
.our-clients .logo:hover {
  filter: grayscale(0);
}
.our-clients-blocks {
  background: var(--clr-semi-dark-blue);
}
.our-clients-blocks .section-heading .section-title {
  color: var(--clr-grey);
}
.our-clients-blocks .blocks-row {
  margin-bottom: -30px;
}
.our-clients-blocks .client-block {
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out 0s;
}
.our-clients-blocks .client-block:hover {
  transform: translateY(-10px);
}
@media (max-width: 767px) {
  .our-clients-blocks .client-block {
    padding: 2rem;
  }
}

/*   End our-clients Section   */

/* Technologies we have Page */



/*   Start faq Section   */
.faq {
  /*Start FAQ-Accordion Rules*/
  /*End FAQ-Accordion Rules*/
}
.faq .faq-accordion .card {
  background-color: transparent;
  padding: 1rem;
  border: 1px solid rgba(var(--clr-main-rgb), 0.35);
}
.faq .faq-accordion .card-header {
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
}
.faq .faq-accordion .card-header .faq-btn {
  width: 100%;
  padding-left: 0;
  padding-right: 20px;
  text-align: left;
  text-decoration: none;
  text-transform: capitalize;
  border-radius: 0;
  font-size: 1.1rem;
  font-weight: 700;
  white-space: normal;
  color: var(--clr-main);
  outline: none;
  box-shadow: none;
  transition: none;
}
.faq .faq-accordion .card-header .faq-btn::before {
  content: "\f068";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 free" !important;
  font-weight: 900;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  left: auto;
  color: var(--clr-main);
}
.faq .faq-accordion .card-header .faq-btn.collapsed::before {
  content: "\f067";
}
.faq .faq-accordion .card-body {
  padding-top: 1rem;
}
.dark-theme .faq .faq-accordion .card-body .faq-answer {
  color: var(--clr-white);
}
/*   End faq Section  */
/*   Start testimonials Section   */
.testimonials .testimonial-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
}
.testimonials .testimonial-content .customer-img img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: 3px solid var(--clr-main);
  margin-bottom: 2rem;
}
.testimonials .content {
  margin-bottom: 2rem;
  position: relative;
}
.testimonials .testimonial-text {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7;
  font-style: italic;
  color: var(--clr-white);
  padding: 0;
  margin: 0;
}
@media (max-width: 991px) {
  .testimonials .testimonial-text {
    font-size: 1.15rem;
  }
}
.testimonials .customer-testimonial {
  position: relative;
  width: 65%;
  margin: 0 auto;
}
@media (max-width: 1199px) {
  .testimonials .customer-testimonial {
    width: 80%;
  }
}
@media (max-width: 575px) {
  .testimonials .customer-testimonial {
    width: 90%;
  }
}
.testimonials .customer-details {
  display: inline-block;
}
.testimonials .customer-details .customer-name,
.testimonials .customer-details .customer-role {
  text-transform: capitalize;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.testimonials .customer-details .customer-name {
  font-size: 1.25rem;
  color: var(--clr-white);
}
.testimonials .customer-details .customer-role {
  font-size: 1rem;
  color: var(--clr-white);
  opacity: 0.75;
}

.testimonials .swiper-button-prev,
.testimonials .swiper-button-next {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: var(--clr-white);
  background-color: rgba(var(--clr-main-rgb), 1);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(var(--clr-white-rgb), 0.25);
  margin-top: 0;
}
.testimonials .swiper-button-prev:hover,
.testimonials .swiper-button-next:hover {
  background-color: rgba(var(--clr-main-rgb), 0.8);
}
.testimonials .swiper-button-prev:after,
.testimonials .swiper-button-next:after {
  content: "";
}
.testimonials .swiper-button-prev .icon,
.testimonials .swiper-button-next .icon {
  display: flex;
}
.testimonials .swiper-button-prev {
  left: 0;
  right: auto;
}
.testimonials .swiper-button-next {
  right: 0;
  left: auto;
}

/*   End testimonials Section   */
/*    Start contact-us Section  */
.contact-us .contact-form-panel .sec-heading {
  margin-bottom: 2rem;
}
@media (max-width: 1199px) {
  .contact-us .contact-form-panel {
    padding-left: 0;
  }
}
.contact-us .contact-info-panel {
  position: relative;
  z-index: 0;
}
.contact-us .contact-info-panel .info-panel {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  border: 1px solid rgba(var(--clr-main-rgb), 0.35);
  border-top: 0.25rem solid rgba(var(--clr-main-rgb), 1);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 1.5rem;
}
.contact-us .contact-info-panel .info-panel:not(:last-child) {
  margin-bottom: 3rem;
}
.contact-us .contact-info-panel .info-panel .location-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
.contact-us .contact-info-panel .info-panel .line-on-side {
  margin-bottom: 2rem;
  width: 4rem;
  height: 2px;
}
.contact-us .contact-info-panel .info-panel .location-address {
  font-size: 1.25rem;
  margin-bottom: 2.25rem;
  text-transform: capitalize;
  opacity: 0.75;
}
.contact-us .contact-info-panel .info-panel .location-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.contact-us .contact-info-panel .info-panel .location-card:last-child {
  margin-bottom: 0;
}
.contact-us .contact-info-panel .info-panel .location-card:hover .icon {
  color: var(--clr-white);
  background-color: rgba(var(--clr-main-rgb), 1);
}
.contact-us .contact-info-panel .info-panel .location-card .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  width: 3rem;
  height: 3rem;
  color: var(--clr-main);
  background-color: rgba(var(--clr-main-rgb), 0.15);
  border-radius: 0.25rem;
  margin-right: 1rem;
  transition: all 0.3s ease-in-out 0s;
}
.contact-us .contact-info-panel .info-panel .location-card .content-title {
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
  color: rgba(var(--clr-main-rgb), 0.75);
}
.contact-us .contact-info-panel .info-panel .location-card .link {
  display: block;
  text-transform: lowercase;
  text-decoration: none;
  font-size: 1.1rem;
  opacity: 0.75;
  margin-bottom: 0.5rem;
}
.contact-us .contact-info-panel .info-panel .location-card .link:last-child {
  margin-bottom: 0;
}
.contact-us .contact-info-panel .info-panel .location-card .link:hover {
  opacity: 1;
}
.contact-us .map-box .mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%;
}
.contact-us .map-box .mapouter .gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}
.contact-us .map-box .mapouter .map-iframe {
  width: 100%;
  height: 500px;
  border: none;
}

/*End user-form Rules*/
/*    End contact-us Section  */
/*   Start take-action Section   */
.take-action {
  background: var(--clr-dark-blue);
  padding: 150px 0;
}
.take-action .cta-link {
  background-color: transparent;
  border-color: var(--clr-white);
  color: var(--clr-white);
}
.take-action .cta-link:hover {
  background-color: var(--clr-main);
  border-color: var(--clr-main);
  color: var(--clr-white);
}

.subscribe .overlay-color {
  opacity: 0.75 !important;
}
.subscribe .section-heading .section-title {
  color: var(--clr-grey);
}
.subscribe .section-heading .section-subtitle {
  color: var(--clr-grey);
}
.subscribe .section-heading .line {
  background-color: var(--clr-white);
}
.subscribe .sec-heading .title {
  color: var(--clr-grey);
}
.subscribe .sec-heading .subtitle {
  color: var(--clr-grey);
}
.subscribe .sec-heading .bottom-line {
  background-color: var(--clr-white);
}
.subscribe .subscribe-btn:hover {
  background: transparent;
  color: var(--clr-white);
  border-color: var(--clr-white);
}

/*   End take-action Section   */
/*    Start page-footer Section  */
.page-footer {
  padding-top: 4rem;
  position: relative;
  z-index: 0;
}

.footer-cols {
  overflow: hidden;
  margin-bottom: 0;
}

.footer-col {
  margin-bottom: 1.5rem;
}

.footer-col-title {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1.5rem;
  color: var(--clr-semi-dark-blue);
}

.footer-logo {
  margin-bottom: 1.5rem;
  max-width: 30%;
}

.footer-text-about-us {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75;
  padding-right: 1rem;
  margin-bottom: 1.5rem;
  color: var(--clr-dark-grey);
}

.sc-wrapper {
  padding: 0;
}

.contact-info-card {
  font-weight: 600;
  padding: 0;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out 0s;
}
.contact-info-card:last-of-type {
  padding-bottom: 0;
}
.contact-info-card .icon {
  font-size: 1.5rem;
  width: 1.5rem;
  text-align: center;
  margin-right: 0.5rem;
  vertical-align: middle;
  color: var(--clr-main);
}
.contact-info-card .info {
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--clr-dark-grey);
  display: inline-block;
  transition: all 0.25s ease-in-out 0s;
}
.contact-info-card .info:hover {
  color: var(--clr-main);
  transform: translateX(0.25rem);
}

.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-menu-item {
  display: block;
  position: relative;
  padding-bottom: 1rem;
}
.footer-menu-item .icon {
  font-size: 0.75rem;
  width: 1.5rem;
  text-align: right;
  margin-right: 0.5rem;
  vertical-align: middle;
  color: var(--clr-main);
}
.footer-menu-link {
  font-weight: 500;
  font-size: 0.85rem;
  text-decoration: none;
  text-transform: capitalize;
  text-decoration: none;
  color: var(--clr-semi-dark);
  display: inline-block;
  transition: all 0.25s ease-in-out 0s;
}
.footer-menu-link:hover {
  color: var(--clr-main);
  text-decoration: none;
  transform: translateX(0.25rem);
}

.copyrights {
  padding: 0.5rem 0;
  overflow: hidden;
}
.copyrights .creadits {
  text-align: left;
  font-weight: 600;
  margin: 0;
  font-size: 0.85rem;
  color: var(--clr-dark-grey);
}
.copyrights .creadits .link {
  font-weight: 600;
  color: var(--clr-main);
  text-decoration: none;
}
.copyrights .terms-links > a {
  text-decoration: none !important;
  font-weight: 600;
  transition: all 0.5s ease-in-out 0s;
  color: var(--clr-dark-grey);
  text-decoration: none;
}

.dark-color-footer {
  background-color: var(--clr-dark-blue);
}
.dark-color-footer .sc-wrapper .sc-list .sc-item:hover .sc-icon,
.dark-color-footer .terms-links,
.dark-color-footer .copyrights .creadits .link {
  color: var(--clr-main);
}
.dark-color-footer .footer-col-title {
  color: var(--clr-white);
}
.dark-color-footer .footer-text-about-us,
.dark-color-footer .contact-info-card .info,
.dark-color-footer .contact-info-card .info:hover,
.dark-color-footer .footer-menu .footer-menu-link,
.dark-color-footer .copyrights .creadits,
.dark-color-footer .terms-links > a {
  color: var(--clr-grey);
}
.dark-color-footer .sc-wrapper .sc-list .sc-item .sc-icon,
.dark-color-footer .contact-info-card .info:hover,
.dark-color-footer .footer-menu .footer-menu-link:hover,
.dark-color-footer .copyrights .creadits .link {
  color: var(--clr-white);
}

.light-color-footer {
  background-color: var(--clr-grey);
}
.light-color-footer .footer-menu-item .icon,
.light-color-footer .contact-info-card .icon,
.light-color-footer .terms-links,
.light-color-footer .copyrights .creadits .link,
.light-color-footer .sc-wrapper .sc-list .sc-item:hover .sc-icon {
  color: var(--clr-main);
}
.light-color-footer .footer-text-about-us,
.light-color-footer .contact-info-card .info,
.light-color-footer .footer-menu .footer-menu-link,
.light-color-footer .copyrights .creadits,
.light-color-footer .terms-links > a {
  color: var(--clr-dark-grey);
}
.light-color-footer .contact-info-card .info:hover,
.light-color-footer .footer-menu .footer-menu-link:hover,
.light-color-footer .terms-links > a:hover {
  color: var(--clr-semi-dark-blue);
}

.main-color-footer {
  background-color: var(--clr-main);
  border-top: 2px solid var(--clr-white);
}
.main-color-footer .copyrights {
  border-color: var(--clr-grey);
}
.main-color-footer .sc-wrapper .sc-list .sc-item:hover .sc-icon {
  color: var(--clr-main);
}
.main-color-footer .footer-menu-item .icon,
.main-color-footer .contact-info-card .icon,
.main-color-footer .terms-links,
.main-color-footer .copyrights .creadits .link {
  color: var(--clr-grey);
}
.main-color-footer .footer-col-title,
.main-color-footer .footer-text-about-us,
.main-color-footer .contact-info-card .info,
.main-color-footer .footer-menu .footer-menu-link,
.main-color-footer .copyrights .creadits,
.main-color-footer .terms-links > a {
  color: var(--clr-grey);
}
.main-color-footer .contact-info-card .info:hover,
.main-color-footer .footer-menu .footer-menu-link:hover,
.main-color-footer .terms-links > a:hover {
  color: var(--clr-white);
}

.index-page-footer {
  padding: 20px 0;
  text-align: center;
  border-top: 2px solid rgba(var(--clr-main-rgb), 0.5);
}
.index-page-footer .footer-brand {
  text-decoration: none;
  display: inline-block;
  padding-bottom: 1rem;
  width: auto;
}
.index-page-footer .footer-brand img {
  width: 120px;
}
.index-page-footer .copyrights {
  display: block;
  text-align: center;
  color: var(--clr-main);
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}

/*    End page-footer Section  */
/*    Start blog Page  */
.blog .post-main-area .post-info, .blog .posts-grid .post-summary .post-info {
  margin-bottom: 0.5rem;
}
.blog .post-main-area .post-info .info, .blog .posts-grid .post-summary .post-info .info {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 0.85rem;
  text-transform: capitalize;
  text-decoration: none;
  margin-right: 1.5rem;
  opacity: 0.75;
  transition: color 0.3s ease 0s;
}
.blog .post-main-area .post-info .info:not(:last-child):before, .blog .posts-grid .post-summary .post-info .info:not(:last-child):before {
  content: "|";
  margin: 0 0.75rem;
  position: absolute;
  left: 100%;
  opacity: 0.75;
}
.blog .post-main-area .post-info .info:hover, .blog .posts-grid .post-summary .post-info .info:hover {
  color: var(--clr-main);
}
.blog .post-main-area .post-info .info .icon, .blog .posts-grid .post-summary .post-info .info .icon {
  color: var(--clr-main);
  margin-right: 0.5rem;
  font-size: 1rem;
}
.blog .box {
  box-shadow: 0px 3px 20px 0px rgba(var(--clr-dark-blue-rgb), 0.1);
}
.blog .blog-sidebar {
  padding-left: 2rem;
  position: sticky;
  top: 7.5rem;
}
@media (max-width: 991px) {
  .blog .blog-sidebar {
    padding-left: 0;
  }
}
.blog .sidebar-box {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}
.blog .sidebar-box .sidebar-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.blog .sidebar-box .sidebar-box-title {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 1rem 0;
}
.blog .search .search-form {
  position: relative;
  z-index: 0;
}
.blog .search .search-input {
  border: 2px solid rgba(var(--clr-main-rgb), 0.5);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  outline: none;
  -webkit-appearance: none;
  width: 100%;
  transition: all 0.5s ease-in-out 0s;
}
.blog .search .search-input:focus {
  border-color: rgba(var(--clr-main-rgb), 1);
}
.blog .search .search-btn {
  position: absolute;
  width: 50px;
  height: 100%;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: var(--clr-main);
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.blog .search .search-btn .icon {
  color: var(--clr-white);
  transition: all 0.5s ease-in-out 0s;
}
.blog .cats .cat-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: all 0.3s ease 0s;
}
.blog .cats .cat-item:last-child {
  margin-bottom: 0;
}
.blog .cats .cat-item:hover {
  background-color: var(--clr-main);
  color: var(--clr-white);
}
.blog .cats .cat-item .cat-link {
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog .cats .cat-item .cat-count {
  width: 2rem;
  height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog .tags .tags-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0.5rem;
}
.blog .tags .tags-icon-label,
.blog .tags .tag-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  border-radius: 2px;
}
.blog .tags .tags-icon-label {
  min-width: 3rem;
  background: var(--clr-main);
}
.blog .tags .tags-icon-label .icon {
  color: var(--clr-white);
}
.blog .tags .tag-item .tag-link {
  font-weight: 400;
  text-decoration: none;
  text-transform: lowercase;
}
.blog .tags .tag-item:hover {
  background-color: var(--clr-main);
  color: var(--clr-white);
}
.blog .tags .tag-item:hover .tag-link {
  color: inherit;
}
.blog .recent-posts .r-post-item {
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  background-color: rgba(var(--clr-main-rgb), 0.15);
}
.blog .recent-posts .r-post-item:last-child {
  margin-bottom: 1rem;
}
.blog .recent-posts .r-post-item .r-post-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.blog .recent-posts .r-post-img-wrapper {
  margin-right: 1rem;
  overflow: hidden;
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
}
.blog .recent-posts .r-post-img {
  width: 100%;
  height: 100%;
}
.blog .recent-posts .content {
  text-transform: capitalize;
}
.blog .recent-posts .content .r-post-title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  transition: color 0.3s ease 0s;
}
.blog .recent-posts .content .r-post-title:hover {
  color: var(--clr-main);
}
.blog .recent-posts .content .r-post-date {
  font-size: 0.9rem;
  opacity: 0.75;
}
.blog .breadcrumb {
  display: flex;
  align-items: center;
  justify-content: start;
  background: transparent;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}
.blog .breadcrumb .icon {
  margin-right: 0.5rem;
}
.blog .breadcrumb-item {
  color: var(--clr-main);
  text-transform: capitalize;
  font-weight: 700;
}
.blog .breadcrumb-item:before {
  /*Breadcrumb Separator Color*/
  color: var(--clr-main) !important;
  padding-right: 0.5rem;
}
.blog .breadcrumb-item.active {
  color: rgba(var(--clr-main-rgb), 0.5);
}
.blog .breadcrumb-link {
  color: var(--clr-main);
  text-decoration: none;
  transition: color 0.25s ease-in 0s;
}
.blog .breadcrumb-link:hover {
  color: rgba(var(--clr-main-rgb), 1);
}
.blog .posts-grid .post-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 1rem 0;
  border-radius: 0.25rem;
  background-color: rgba(var(--clr-main-rgb), 0.15);
}
.blog .posts-grid .post-box:hover .post-img-wrapper .overlay-color {
  opacity: 0.65;
}
.blog .posts-grid .post-box:hover .post-img-wrapper .post-img {
  transform: scale(1.1);
}
.blog .posts-grid .post-box:hover .post-img-wrapper .icon {
  top: 50%;
  visibility: visible;
  opacity: 1;
}
.blog .posts-grid .post-link {
  text-decoration: none;
}
.blog .posts-grid .post-img-wrapper {
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem 0.25rem 0 0;
  position: relative;
}
.blog .posts-grid .post-img-wrapper .overlay-color {
  opacity: 0;
  z-index: 1;
}
.blog .posts-grid .post-img-wrapper .post-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: all 0.5s ease-in-out 0s;
}
.blog .posts-grid .post-img-wrapper .post-date {
  position: absolute;
  color: var(--clr-white);
  background: rgba(var(--clr-dark-blue-rgb), 0.75);
  padding: 0.25rem 1.25rem;
  top: 0.75rem;
  left: 0.75rem;
  right: auto;
  font-weight: 400;
  text-align: center;
  z-index: 30;
  border-radius: 5rem;
}
.blog .posts-grid .post-summary {
  padding: 1rem;
  position: relative;
}
.blog .posts-grid .post-summary .post-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  transition: color 0.3s ease 0s;
}
.blog .posts-grid .post-summary .post-title:hover {
  color: var(--clr-main);
}
.blog .posts-grid .post-summary .post-excerpt {
  opacity: 0.75;
}
.blog .posts-grid.horizontal .post-box {
  flex-direction: row;
}
.blog .posts-grid.horizontal .post-link {
  display: inline-flex;
}
.blog .posts-grid.horizontal .post-img-wrapper {
  margin-bottom: 0;
  border-radius: 0.25rem;
}
@media (max-width: 767px) {
  .blog .posts-grid.horizontal .post-box {
    flex-direction: column;
  }
  .blog .posts-grid.horizontal .post-summary {
    padding: 1rem;
  }
}
.blog.blog-post {
  margin-top: 15rem;
}
@media (max-width: 1199px) {
  .blog.blog-post {
    margin-top: 10rem;
  }
}
.blog .post-featured-area {
  width: 100%;
  height: auto;
  max-height: 600px;
  overflow: hidden;
  margin-bottom: 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
}
.blog .post-featured-area .featured-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: all 1s ease-in-out 0s;
}
.blog .post-featured-area .swiper-container {
  width: 100%;
  height: auto;
  max-height: 450px;
  margin-left: auto;
  margin-right: auto;
}
.blog .post-featured-area .swiper-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.blog .post-featured-area .swiper-container .swiper-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}
.blog .post-featured-area .swiper-container .swiper-button-prev,
.blog .post-featured-area .swiper-container .swiper-button-next {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: var(--clr-white);
  background-color: rgba(var(--clr-main-rgb), 1);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}
.blog .post-featured-area .swiper-container .swiper-button-prev:hover,
.blog .post-featured-area .swiper-container .swiper-button-next:hover {
  background-color: rgba(var(--clr-main-rgb), 0.8);
}
.blog .post-featured-area .swiper-container .swiper-button-prev:after,
.blog .post-featured-area .swiper-container .swiper-button-next:after {
  content: "";
}
.blog .post-featured-area .swiper-container .swiper-button-prev .icon,
.blog .post-featured-area .swiper-container .swiper-button-next .icon {
  display: flex;
}
.blog .post-featured-area .swiper-container .swiper-button-prev:hover,
.blog .post-featured-area .swiper-container .swiper-button-next:hover {
  background-color: rgba(var(--clr-main-rgb), 1);
}
.blog .post-featured-area .video-source {
  width: 100%;
  height: 450px;
}
.blog .social {
  margin-bottom: 1rem;
}
.blog .social .share-us {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.blog .social .share-us .share-title {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-right: 0.75rem;
}
.blog .social .sc-item {
  margin-bottom: 0;
}
.blog .post-title {
  font-size: 3rem;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 3rem;
}
@media (max-width: 991px) {
  .blog .post-title {
    font-size: 2.25rem;
    margin-bottom: 3rem;
  }
}
.blog .post-main-area {
  margin: 1rem 0;
}
.blog .post-main-area .panel {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
}
.blog .post-main-area .panel .panel-title {
  margin-bottom: 0.75rem;
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: capitalize;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.blog .post-main-area .float-start-direction {
  float: left;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1.5rem;
  width: 50%;
}
.blog .post-main-area .float-end-direction {
  float: right;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
  width: 50%;
}
.blog .post-main-area .share-title {
  margin-right: 1.5rem;
  margin-bottom: 0%;
  text-transform: capitalize;
  opacity: 0.75;
  font-weight: 800;
}
.blog .post-main-area .post-content {
  margin-bottom: 1.5rem;
}
.blog .post-main-area .post-content img {
  border-radius: 0.25rem;
}
.blog .post-main-area .post-content a {
  color: var(--clr-main);
  opacity: 0.85;
  text-decoration: underline;
  transition: all 0.1s ease-in-out 0s;
}
.blog .post-main-area .post-content a:hover {
  opacity: 1;
}
.blog .post-main-area .post-content .post-heading {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.blog .post-main-area .post-content .post-text {
  overflow: hidden;
  font-size: 1.25rem;
  opacity: 0.75;
  line-height: 1.6;
  margin-bottom: 1.25rem;
}
.blog .post-main-area .post-content .post-img-wrapper {
  margin: 1rem 0;
  max-height: 500px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .blog .post-main-area .post-content .post-img-wrapper {
    height: 350px;
  }
}
.blog .post-main-area .post-content .post-img-wrapper img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.blog .post-main-area .post-content .post-list {
  list-style: none;
  margin: 0;
  margin-bottom: 1.5rem;
  padding-left: 0;
}
.blog .post-main-area .post-content .post-list .post-list-item {
  opacity: 0.75;
  padding: 0.5rem 0;
  position: relative;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}
.blog .post-main-area .post-content .post-list .post-list-item .post-list-text {
  text-transform: capitalize;
}
.blog .post-main-area .post-content .post-list .post-list-item .icon {
  color: var(--clr-main);
  margin-right: 0.5rem;
}
.blog .post-main-area .post-content blockquote {
  margin: 2rem 0;
  padding: 3rem 2rem;
  border: none;
  border-left: 0.25rem solid var(--clr-main);
  border-top-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
  position: relative;
  overflow: hidden;
  background-color: rgba(var(--clr-main-rgb), 0.15);
}
.blog .post-main-area .post-content blockquote .post-quote {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
  display: block;
  padding: 1.5rem 0;
}
.blog .post-main-area .post-content blockquote .person-name {
  position: absolute;
  left: 2rem;
  bottom: 1rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--clr-main);
}
.blog .post-main-area .post-content blockquote .icon {
  position: absolute;
  top: 1rem;
  left: 2rem;
  color: rgba(var(--clr-main-rgb), 0.15);
  font-size: 6rem;
}
@media (max-width: 991px) {
  .blog .post-main-area .post-content blockquote {
    padding: 2rem;
  }
  .blog .post-main-area .post-content blockquote .icon {
    left: 3rem;
  }
}
.blog .post-main-area .other-posts .other-post-link {
  text-decoration: none;
}
.blog .post-main-area .other-posts .other-post-link:hover img {
  transform: scale(1.05);
}
.blog .post-main-area .other-posts .other-post-link:hover .icon {
  background: rgba(var(--clr-main-rgb), 1);
}
.blog .post-main-area .other-posts .other-post-link:hover .other-post-title {
  color: var(--clr-main);
}
.blog .post-main-area .other-posts .other-post-img {
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
}
.blog .post-main-area .other-posts .other-post-img > img {
  transform-origin: center;
  transition: all 0.5s ease-in-out 0s;
}
.blog .post-main-area .other-posts .other-post-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  text-transform: capitalize;
  padding-top: 1.5rem;
  margin: 0;
  transition: color 0.2s ease-in-out 0s;
}
.blog .post-main-area .other-posts .prev-post,
.blog .post-main-area .other-posts .next-post {
  position: relative;
}
.blog .post-main-area .other-posts .prev-post .icon,
.blog .post-main-area .other-posts .next-post .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: var(--clr-white);
  background-color: rgba(var(--clr-main-rgb), 1);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}
.blog .post-main-area .other-posts .prev-post .icon:hover,
.blog .post-main-area .other-posts .next-post .icon:hover {
  background-color: rgba(var(--clr-main-rgb), 0.8);
}
.blog .post-main-area .other-posts .prev-post .icon:after,
.blog .post-main-area .other-posts .next-post .icon:after {
  content: "";
}
.blog .post-main-area .other-posts .prev-post .icon .icon,
.blog .post-main-area .other-posts .next-post .icon .icon {
  display: flex;
}
.blog .post-main-area .other-posts .prev-post .icon {
  left: 1rem;
}
.blog .post-main-area .other-posts .next-post .icon {
  right: 1rem;
}
.blog .post-main-area .author-profile .author-info {
  padding: 2rem 1rem 1rem;
  background-color: rgba(var(--clr-main-rgb), 0.15);
}
.blog .post-main-area .author-profile .author-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  border: 3px solid var(--clr-main);
}
.blog .post-main-area .author-profile .avatar-img {
  width: 100%;
  height: 100%;
}
.blog .post-main-area .author-profile .author-disc {
  overflow: hidden;
  padding: 1rem;
  padding-top: 0;
}
.blog .post-main-area .author-profile .author-disc .author-link {
  text-decoration: none;
  color: var(--clr-main);
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: capitalize;
}
.blog .post-main-area .comments-area .comment-wrapper {
  margin-bottom: 2rem;
}
.blog .post-main-area .comments-area .comment-reply {
  padding-left: 3rem;
}
.blog .post-main-area .comments-area .author-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  border: 3px solid var(--clr-main);
}
.blog .post-main-area .comments-area .author-avatar .author-avatar-img {
  width: 100%;
  height: 100%;
}
.blog .post-main-area .comments-area .comment-body {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(var(--clr-main-rgb), 0.5);
  padding: 1rem;
  padding-top: 0;
  overflow: hidden;
}
.blog .post-main-area .comments-area .comment-body .comment-author-link {
  text-decoration: none;
  color: var(--clr-main);
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: capitalize;
  transition: color 0.3s ease-in-out 0s;
}
.blog .post-main-area .comments-area .comment-body .comment-date {
  margin-bottom: 1rem;
}
.blog .post-main-area .comments-area .comment-body .comment-date .date-link {
  font-size: 0.8rem;
  opacity: 0.75;
  text-decoration: none;
}
.blog .post-main-area .comments-area .comment-body .comment-text {
  font-size: 1rem;
  opacity: 0.75;
}
.blog .post-main-area .comments-area .comment-body .reply-action {
  text-align: right;
}
.blog .post-main-area .comments-area .comment-body .reply-action .reply-link {
  text-decoration: none;
  text-transform: uppercase;
  color: rgba(var(--clr-main-rgb), 0.7);
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  padding-left: 1.5rem;
}
.blog .post-main-area .comments-area .comment-body .reply-action .reply-link:hover {
  color: rgba(var(--clr-main-rgb), 1);
}
.blog .post-main-area .comments-area .comment-body .reply-action .reply-link .icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--clr-main);
}

/*    End blog Page  */
/*   Start pricing Section  */
.pricing {
  overflow: hidden;
  position: relative;
}
.pricing .plan {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(var(--clr-main-rgb), 0.35);
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 2rem 1rem;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 1px 1px 10px 0px rgba(var(--clr-dark-blue-rgb), 0.15);
}
@media (max-width: 1199px) {
  .pricing .plan {
    margin-bottom: 2rem;
    overflow: hidden;
  }
}
.pricing .plan .plan-head {
  position: relative;
  text-align: center;
}
.pricing .plan .plan-head::before {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: var(--clr-main);
}
.pricing .plan .plan-head .plan-icon {
  display: block;
  font-size: 4rem;
  opacity: 0.75;
  color: var(--clr-main);
}
.pricing .plan .plan-head .plane-name {
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1.25rem;
}
.pricing .plan .plan-head .plan-price {
  display: inline-block;
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
}
.pricing .plan .plan-head .plan-price .price {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
}
.pricing .plan .plan-head .plan-price .currency-symbol {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 500;
  top: 1rem;
  left: -1rem;
  line-height: 0;
  vertical-align: baseline;
  display: inline-block;
  color: var(--clr-main);
}
.pricing .plan .plan-head .plan-price .per {
  font-size: 1.1rem;
  font-style: italic;
  opacity: 0.75;
  padding: 0.25rem;
}
.pricing .plan .plan-head .plan-desc {
  font-size: 0.9rem;
  opacity: 0.75;
}
.pricing .plan .plan-list {
  list-style: none;
  margin: 0;
  padding: 1rem 0;
  margin-bottom: 1rem;
}
.pricing .plan .plan-list .plan-feat {
  position: relative;
  padding: 0.75rem 0;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing .plan .plan-list .plan-feat .feat-icon {
  color: var(--clr-main);
  font-size: 1.5rem;
}
.pricing .plan .plan-cta .cta-btn {
  width: 100%;
}
.pricing .plan.featured {
  background-color: var(--clr-main);
}
.pricing .plan.featured:before {
  content: "most poupular";
  display: inline-block;
  padding: 0.25rem 5rem;
  color: var(--clr-main);
  background-color: var(--clr-white);
  top: 3rem;
  right: -4rem;
  left: auto;
  position: absolute;
  font-weight: 800;
  font-size: 0.85rem;
  transform: rotate(45deg);
}
.pricing .plan.featured .plan-head::before {
  background-color: var(--clr-white);
}
.pricing .plan.featured .plan-head .plan-icon,
.pricing .plan.featured .plan-head .plane-name,
.pricing .plan.featured .plan-head .plan-desc {
  color: var(--clr-white);
}
.pricing .plan.featured .plan-head .plan-price .price,
.pricing .plan.featured .plan-head .plan-price .currency-symbol,
.pricing .plan.featured .plan-head .plan-price .per {
  color: var(--clr-white);
}
.pricing .plan.featured .plan-list .plan-feat,
.pricing .plan.featured .plan-list .feat-icon {
  color: var(--clr-white);
}
.pricing .plan.featured .plan-cta .cta-btn {
  color: var(--clr-white);
  border-color: var(--clr-white);
}
.pricing .plan.featured .plan-cta .cta-btn:hover {
  color: var(--clr-main);
  background-color: var(--clr-white);
}

/*    End pricing Section  */
/*   Start stats section Rules  */
.stats {
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: var(--clr-dark-blue);
}
.stats .section-heading .line-solid-color {
  background: var(--clr-white);
}
.stats .stat-box {
  position: relative;
  text-align: center;
}
@media (max-width: 991px) {
  .stats .stat-box {
    margin-bottom: 2rem;
  }
}
@media (max-width: 767px) {
  .stats .stat-box {
    padding: 1rem 3rem;
  }
}
.stats .stat-box-inner {
  text-align: center;
  position: relative;
  padding: 2rem;
  color: var(--clr-white);
  background-color: rgba(var(--clr-dark-blue-rgb), 0.25);
  border: 1px solid rgba(var(--clr-main-rgb), 0.35);
  border-top: 0.25rem solid rgba(var(--clr-main-rgb), 1);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.stats .stat-icon {
  font-size: 3rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-main);
  opacity: 0.65;
  transition: all 0.2s ease-in-out 0s;
}
.stats .stat-num {
  display: block;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .stats .stat-num {
    font-size: 2rem;
  }
}
.stats .stat-desc {
  font-size: 1.25rem;
  font-weight: 400;
  display: block;
  text-transform: capitalize;
}

/*  End  stats section Rules    */
/*    Start dark theme rules  */
body.dark-theme {
  color: var(--clr-white);
  background: var(--clr-dark-blue);
  /* about section */
}
body.dark-theme .header-basic .header-logo .logo-img.light-logo {
  display: block;
}
body.dark-theme .header-basic .header-logo .logo-img.dark-logo {
  display: none;
}
body.dark-theme .header-basic .controls-box .header-search-btn {
  color: var(--clr-white);
}
body.dark-theme .header-basic.inner-page-header .header-logo .logo-img.light-logo {
  display: block;
}
body.dark-theme .header-basic.inner-page-header .header-logo .logo-img.dark-logo {
  display: none;
}
body.dark-theme .page-hero .hero-social-icons .sc-list .sc-item .sc-link {
  color: var(--clr-white);
}
body.dark-theme .custom-form-area input[type=email],
body.dark-theme .custom-form-area input[type=text],
body.dark-theme .custom-form-area input[type=password],
body.dark-theme .custom-form-area input[type=url],
body.dark-theme .custom-form-area input[type=search],
body.dark-theme .custom-form-area input[type=number],
body.dark-theme .custom-form-area select,
body.dark-theme .custom-form-area textarea {
  border: 1px solid rgba(var(--clr-white-rgb), 0.25);
  background: var(--clr-dark-blue);
  color: var(--clr-white);
}
body.dark-theme .custom-form-area.input-under-lined input[type=email],
body.dark-theme .custom-form-area.input-under-lined input[type=text],
body.dark-theme .custom-form-area.input-under-lined input[type=password],
body.dark-theme .custom-form-area.input-under-lined input[type=url],
body.dark-theme .custom-form-area.input-under-lined input[type=search],
body.dark-theme .custom-form-area.input-under-lined input[type=number],
body.dark-theme .custom-form-area.input-under-lined select,
body.dark-theme .custom-form-area.input-under-lined textarea {
  border: none;
  border-bottom: 1px solid rgba(var(--clr-main-rgb), 0.35);
}
body.dark-theme .team-member .profile {
  background-color: var(--clr-semi-dark-blue);
}
/* body.dark-theme .about .cta-area .signature .signature-img {
} */
body.dark-theme .about .stats-counter.stats-cards .stat-box {
  background: var(--clr-semi-dark-blue);
}

.tech-logos{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.technology-logo{
  background-color: white;
  border: 2px solid white;
  border-radius: 0.5rem;
  filter: grayscale(0.2);
  transition: all .3s ease-out;
  width: '160px';
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 5px;
  align-content: center;
  
  
  

}

.technology-logo:hover{
  filter: grayscale(0);
  transform: translate(0, -5px);
}





/*    End dark theme rules  *//*# sourceMappingURL=main-LTR.css.map */