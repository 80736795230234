.submenu {
    position: absolute; /* Position it absolutely */
    background-color: white; /* Background color */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
    z-index: 1000; /* Make sure it's above other elements */
    width: 200px;
    color: black;
    list-style: none;
}

.submenu-item {
    cursor: pointer; /* Pointer cursor */
}

.submenu-item:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}
.submenu-item ol, ul{
padding-left: 15px !important;
}
